@import '../../css/variables';
@import '../../css/mixins';

.component-footer {
    .logo {
        margin: 0 auto;
        padding-top: 55px;
        text-align: center;
    }
    .links {
        margin-top: 55px;
        color: #fff;
        .link {
            margin: 10px 0;

            a {
                color: #fff;
                text-decoration: none;
                font-size: 0.8rem;
                &:hover {
                    color: $secondary;
                }
            }
        }
        .copyright {
            margin-top: 20px;
            font-size: 0.8rem;
        }
    }
    .connect-label {
        margin-top: 10px;
        margin-bottom: 5px;
    }
    .social {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        a {
            display: block;
            width: 32px;
            height: 32px;
            margin-right: 10px;
            font-size: 32px;
            color: #fff;
            font-family: 'icon';

            &:hover {
                text-decoration: none;
                color: $secondary;
            }
        }
    }
}

@media #{$mobile} {
    .component-footer {
        .logo {
            max-width: 260px;
        }
        .links {
            padding-left: 12%;
        }
    }
}

@media #{$desktop} {
    .component-footer {
        .logo {
            max-width: 291px;
        }
        .links {
            padding-left: 20%;
        }
    }
}