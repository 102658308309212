@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/variables';

@import '../../css/variables';
@import '../../css/mixins';

$padding-mobile: 35px;
$padding-desktop: 75px;
$label-margin-mobile: 10px;
$label-margin-desktop: 20px;
.component-products-list {
    .row {
        .col-12 {
            margin-bottom: 10px;
            // &:last-child {
            //     margin-bottom: 0;
            // }
        }
    }
    .products-list-item {
        min-height: 450px;
        min-width: 300px;
        border: 3px solid #e8e7e7;
        border-radius: 2px;
        padding: $padding-mobile;
        .product-label {
            display: flex;
            align-items: center;
            font-family: 'BentonSans-Regular';
            font-size: 1.25rem;
            line-height: 0.75em;
            color: #65676c;
            position: relative;
            padding-left: $padding-mobile;
            padding-top: 5px;
            padding-bottom: 5px;
            left: -$padding-mobile;
            margin-top: $label-margin-mobile;
            height: $padding-desktop;
            max-width: 400px;
            img {
                max-height: 100%;
            }
            &:first-child {
                margin-top: 0;
            }
            &::before {
                content: '';
                display: block;
                width: 16px;
                position: absolute;
                left: -3px;
                top: 0;
                bottom: 0;
            }
            &.apligraf-bg {
                &::before {
                    background-color: #00a09e;
                }
            }
            &.puraplyam-bg, &.puraplymz-bg, &.puraplyxt-bg, &.puraplysx-bg {
                &::before {
                    background-color: #a6228c;
                }
            }
            &.nushield-bg {
                &::before {
                    background-color: #37b14a;
                }
            }
            &.affinity-bg, &.novachor-bg {
                &::before {
                    background-color: #ec2b38;
                }
            }
            &.puraforce-bg {
                &::before {
                    background-color: #01a0d5;
                }
            }
            &.fiberos-bg, &.ocmp-bg {
                &::before {
                    background-color: $subhead;
                }
            }
            &.cygnus-bg {
                padding-top: 0;
                padding-bottom: 0;
                &.block-bg {
                    padding: 15px 30px 15px 50px;
                    background-color: $primary;
                }
                &::before {
                    background-color: #9099a8;
                }
            }
            &.via-cygnus-bg,
            &.via-matrix-bg {
                padding-top: 0;
                padding-bottom: 0;
                &.block-bg {
                    padding: 15px 30px 15px 50px;
                    background-color: $primary;
                }
                &::before {
                    background-color: #006b56;
                }
            }
        }
        .image-holder {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;
            margin-bottom: 10px;
            img {
                max-width: 75%;
                max-height: 100%;
            }
        }
        h3 {
            font-size: 1.75rem;
            letter-spacing: 0.2em;
            font-family: 'BentonSansCond-Bold';
            color: $primary;
        }
        p {
            font-family: 'BentonSans-Book';
            font-size: 1rem;
            margin-bottom: 10px;
            strong {
                font-family: 'BentonSans-Medium';
                sup {
                    font-family: 'BentonSans-Book';
                }
            }
            & + p {
                margin-bottom: 0;
            }
        }
        .cta-holder {
            padding-top: 50px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: center;

            .text {
                padding: 0.375rem 0.75rem;
                font-family: 'BentonSans-Medium';
                font-weight: 400;
            }
        }
    }
}

@media #{$desktop} {
    .component-products-list {
        .row {
            .col-12 {
                display: flex;
                align-self: stretch;
                margin-bottom: 30px;
            }
        }
        .products-list-item {
            min-height: 550px;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            .product-label {
                font-size: 2.25rem;
            }
            .image-holder {
                img {
                    max-width: 90%;
                }
            }
            .cta-holder {
                flex-grow: 1;
            }
        }

        .product-label {
            &.via-cygnus-bg {
                img {
                  min-width: 21rem;
                }
            }
        }
    }
}

@media #{$mobile} {
    .component-products-list {
        .row {
            .col-12 {
                padding-left: 0;
                padding-right: 0;
            }
        }
        .product-label {
            &.via-cygnus-bg {
                img {
                    min-width: 17rem;
                }
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .component-products-list {
        .products-list-item {
            padding: $padding-desktop;
            .product-label {
                display: flex;
                align-items: center;
                margin-top: $label-margin-desktop;
                height: $padding-desktop;
                padding-left: $padding-desktop;
                left: -$padding-desktop;
            }
        }
    }
}

$height-lg: 380px;
@include media-breakpoint-up(lg) {
    .component-products-list {
        .products-list-item {
            .image-holder {
                height: $height-lg;
                margin-bottom: 5px;
            }
            .product-label + .product-label + .image-holder {
                height: $height-lg - ($label-margin-desktop + $padding-desktop);
            }
        }
    }
}

$height-xl: 450px;
@include media-breakpoint-up(xl) {
    .component-products-list {
        .products-list-item {
            .image-holder {
                height: $height-xl;

                &.tall-image {
                    img {
                        height: $height-xl * 0.625;
                    }
                }
            }
            .product-label + .product-label + .image-holder {
                height: $height-xl - ($label-margin-desktop + $padding-desktop);
            }
        }
    }
}
