@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/variables';

@import './variables';
@import './mixins';

@font-face {
    font-family: 'BentonSans-Book';
    src: url('/fonts/BentonSans-Book.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'BentonSans-Regular';
    src: url('/fonts/BentonSans-Regular.eot');
    src: url('/fonts/BentonSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/BentonSans-Regular.woff2') format('woff2'),
        url('/fonts/BentonSans-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'BentonSans-Medium';
    src: url('/fonts/BentonSans-Medium.eot');
    src: url('/fonts/BentonSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('/fonts/BentonSans-Medium.woff2') format('woff2'),
        url('/fonts/BentonSans-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'BentonSans-Bold';
    src: url('/fonts/BentonSans-Bold.eot');
    src: url('/fonts/BentonSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/BentonSans-Bold.woff2') format('woff2'),
        url('/fonts/BentonSans-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'BentonSans-Black';
    src: url('/fonts/BentonSans-Black.eot');
    src: url('/fonts/BentonSans-Black.eot?#iefix') format('embedded-opentype'),
        url('/fonts/BentonSans-Black.woff2') format('woff2'),
        url('/fonts/BentonSans-Black.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'BentonSansCond-Regular';
    src: url('/fonts/BentonSansCond-Regular.eot');
    src: url('/fonts/BentonSansCond-Regular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/BentonSansCond-Regular.woff2') format('woff2'),
        url('/fonts/BentonSansCond-Regular.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'BentonSansCond-Medium';
    src: url('/fonts/BentonSansCond-Medium.eot');
    src: url('/fonts/BentonSansCond-Medium.eot?#iefix') format('embedded-opentype'),
        url('/fonts/BentonSansCond-Medium.woff2') format('woff2'),
        url('/fonts/BentonSansCond-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'BentonSansCond-Bold';
    src: url('/fonts/BentonSansCond-Bold.eot');
    src: url('/fonts/BentonSansCond-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/BentonSansCond-Bold.woff2') format('woff2'),
        url('/fonts/BentonSansCond-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'icon';
    src: url('/fonts/icon.eot?dpczpe');
    src: url('/fonts/icon.eot?dpczpe#iefix') format('embedded-opentype'),
        url('/fonts/icon.ttf?dpczpe') format('truetype'),
        url('/fonts/icon.woff?dpczpe') format('woff'),
        url('/fonts/icon.svg?dpczpe#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FontAwesome-Bold';
    font-style: normal;
    font-weight: 900;
    font-display: auto;
    src: url("/fonts/FontAwesome-Bold.eot");
    src: url("/fonts/FontAwesome-Bold.eot?#iefix") format("embedded-opentype"),
        url("/fonts/FontAwesome-Bold.woff") format("woff");
}

@font-face {
    font-family: 'FontAwesome-Regular';
    font-style: normal;
    font-weight: 400;
    font-display: auto;
    src: url("/fonts/FontAwesome-Regular.eot");
    src: url("/fonts/FontAwesome-Regular.eot?#iefix") format("embedded-opentype"),
        url("/fonts/FontAwesome-Regular.woff") format("woff");
}

.icon-info-circle {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: 'FontAwesome-Regular' !important;

    &.font-weight-bold {
        font-family: 'FontAwesome-Bold' !important;
    }

    &::before {
        content: "\f05a";
    }
}

[class^="icon-"],
[class*=" icon-"] {
    font-family: 'icon' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-instagram:before {
    content: "\e900";
}

.icon-twitter:before {
    content: "\e901";
}

.icon-linkedin:before {
    content: "\e902";
}

.icon-facebook:before {
    content: "\e903";
}

.icon-download:before {
    content: "\e904";
}

.icon-menu-close:before {
    content: "\e905";
}

.icon-menu-expand:before {
    content: "\e906";
}

.icon-arrow-down:before {
    content: "\e907";
}

.icon-arrow:before {
    content: "\e908";
}

.icon-filters:before {
    content: "\e909";
}

.icon-quote:before {
    content: "\e90a";
}

.icon-search:before {
    content: "\e90b";
}

.icon-wave:before {
    content: "\e90c";
}

html {
  scroll-behavior: smooth;
}

body {
    position: relative;
    color: $text-color;
    font-family: 'BentonSans-Regular';
}

main {
    min-width: 375px;
}

a {
    color: $link;
    text-decoration: none;

    &:visited {
        color: $link;
        text-decoration: none;
    }

    &:hover {
        color: $link;
        text-decoration: underline;
        transition: color 0.5s;
    }
}

a[href^='tel:'] {
    color: $text-color;
}

b,
strong,
.btn {
    font-family: 'BentonSans-Medium';
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;

    &.bulleted {
        list-style-type: disc;
        margin: 0.5em 0;
        padding-left: 18px;

        li {
            margin: 0.5em 0;
        }

        ul.bulleted {
            list-style-type: none;
            li {
                margin: 0.5em 0;
                &:before {
                    content: '\002D';
                    position: absolute;
                    margin-left: -15px;
                }
            }
        }
    }

    &.padded {
        li {
            padding: 0.2em 0;
        }
    }
}

ol {
    li {
        line-height: 1.5em;
    }

    &.padded {
        li {
            padding: 0.3em 0;
        }
    }
}

h1 {
    font-size: 2.25rem;
    font-family: 'BentonSansCond-Bold';
    letter-spacing: 0.667rem;
    color: $primary;
    text-transform: uppercase;
    margin-bottom: $h1-margin;
}

h2 {
    font-family: 'BentonSansCond-Bold';
    font-size: 2.25rem;
    color: $primary;
    letter-spacing: 0.667rem;
    text-transform: uppercase;
    margin-bottom: $h2-margin;
}

h3 {
    font-family: 'BentonSans-Regular';
    font-size: 1.5rem;
    line-height: 1.5em;
    color: $text-color;
}

h4 {
    font-family: 'BentonSans-Medium';
    font-size: 1.125rem;
    line-height: 1.5em;
    color: $blue;
}

h5 {
    font-family: 'BentonSans-Bold';
    font-size: 1rem;
    line-height: 1.5em;
    color: $text-color;
}

section {
    padding-top: $section-padding;
    padding-bottom: $section-padding;
}

footer {
    background: $primary;
    padding-bottom: $section-padding;
}

.text-underlined {
    text-decoration: underline !important;
}

.references {
    font-size: 0.75rem;
    color: $grey-900;

    ol {
        margin-top: 5px;
        padding-left: 10px;
        line-height: 16px;
    }
}

.w-60 {
    width: 60% !important;
}

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    border-spacing: 0;

    td,
    th {
        padding: 0.75rem;
        vertical-align: top;
        border-top: 1px solid #eceeef;
        text-align: left;
    }

    thead th {
        vertical-align: bottom;
        border-bottom: 2px solid #eceeef;
    }

    tbody+tbody {
        border-top: 2px solid #eceeef;
    }

    & .table {
        background-color: #fff;
    }

    &.table-blue {
        margin-bottom: 0;

        th,
        td {
            border: 1px solid $grey-100;
            text-align: center;
            padding: 0.25rem;
        }

        th {
            background-color: $primary;
            color: #fff;
            font-weight: bold;
        }

        td {
            background-color: $grey-200;
            font-family: 'BentonSans-Book';
        }
    }

    &.table-blue-white {
        margin-bottom: 0;

        th,
        td {
            text-align: center;
            padding: 0.25rem;
        }

        th {
            background-color: $primary;
            color: #fff;
            font-weight: bold;
            padding-top: 0.875rem;
            padding-bottom: 0.875rem;
        }

        td {
            font-family: 'BentonSans-Book';
            border-bottom: 2px solid $primary;
            padding: 0.75rem;
        }

        tr:last-child {
            td {
                border-bottom: none;
            }
        }
    }

    &.table-cygnus-dual {
        th.table-heading {
            padding-top: 7px;
            padding-bottom: 7px;
            font-size: 1rem;
        }
        th.column-heading {
            background-color: $light-blue-200;
            color: $primary;
            font-size: 1rem;
        }
        tr {
            th, td {
                padding-top: 5px;
                padding-bottom: 5px;
                border-right: 3px solid $green;
                font-size: 1rem;
                vertical-align: middle;

                &:last-child {
                    border-right: none;
                }
            }
        }
    }

    &.table-green-white {
      margin-bottom: 0;

      th,
      td {
        text-align: center;
        padding: 0.25rem;
      }

      th {
        background-color: $dark-green;
        color: #fff;
        font-weight: bold;
        padding-top: 0.875rem;
        padding-bottom: 0.875rem;
      }

      td {
        font-family: 'BentonSans-Book';
        border-bottom: 2px solid $dark-green;
        padding: 0.75rem;
      }

      tr {
        th, td {
          border-right: 2px solid $dark-green;
          border-bottom: 1px solid $dark-green;
        }
      }

      tr:last-child {
        td {
          border-bottom: 2px solid $dark-green;
        }
      }
    }

    &.table-grey {
        margin-bottom: 0;
        border-top: 1px solid $dark-grey-300;

        th,
        td {
            border-right: 1px solid $dark-grey-300;
            border-bottom: 1px solid $dark-grey-300;
            text-align: center;
            padding: 0.25rem;
        }

        th:first-child,
        td:first-child {
            border-left: 1px solid $dark-grey-300;
        }

        th {
            background-color: $grey-500;
            color: $dark-grey-300;
            font-weight: bold;
            font-family: 'BentonSans-Regular';
        }
    }

    &.table-blue-reverse {
        margin-bottom: 0;
        border-top: 1px solid $dark-grey-300;

        th,
        td {
            border-right: 1px solid $dark-grey-300;
            border-bottom: 1px solid $dark-grey-300;
            border-top: 0;
            padding: 1.25rem;
            color: #000;
            text-align: center;
        }

        th:first-child,
        td:first-child {
            border-left: 1px solid $dark-grey-300;
            text-align: left;
        }

        th {
            font-family: 'BentonSans-Medium';
            background-color: $grey-500;
        }

        td:first-child {
            background: $subhead;
            color: $white;
            border-bottom: 1px solid $white;
        }

        tr:last-child {
            td:first-child {
                border-bottom: 1px solid $dark-grey-300;
            }
        }
    }
}

.table-sm {

    td,
    th {
        padding: 0.3rem;
    }
}

.table-bordered {
    border: 1px solid #eceeef;

    td,
    th {
        border: 1px solid #eceeef;
    }

    thead td,
    thead th {
        border-bottom-width: 2px;
    }
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.0375);
}

.table-active {
    background-color: rgba(0, 0, 0, 0.075);

    &>td,
    &>th {
        background-color: rgba(0, 0, 0, 0.075);
    }
}

.table-hover {
    .table-active:hover {
        background-color: rgba(0, 0, 0, 0.075);

        &>td,
        &>th {
            background-color: rgba(0, 0, 0, 0.075);
        }
    }
}

.thead-inverse th {
    color: #fff;
    background-color: #292b2c;
}

.thead-default th {
    color: #464a4c;
    background-color: #eceeef;
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    &.table-bordered {
        border: 0;
    }
}

.btn-primary,
.btn-primary:visited {
    padding-right: 2rem;
    padding-left: 2rem;
    background-color: $primary !important;
    color: #fff !important;
    border-color: transparent;
    border-radius: 0;
    transition: all .3s;
    white-space: nowrap;

    &[disabled] {
        border-color: transparent;
        color: #b8b8b8 !important;
        cursor: not-allowed;
        &:hover {
            color: #b8b8b8 !important;
            border-color: transparent;
        }
    }
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover:visited {
    color: $secondary !important;
}

.btn-pink {
    background: $white;
    color: $pink;
    border: 1px solid $pink;

    &:hover,
    &:active,
    &:hover:visited {
        color: $white;
        background: $pink;
        transition: all 0.5s;
    }

    &:focus {
        box-shadow: none;
    }
}

// Custom arrows
.carousel-control-prev,
.carousel-control-next {
    opacity: 1;

    &:hover {

        .carousel-control-prev-icon,
        .carousel-control-next-icon {
            opacity: 0.5;
            transition: opacity 0.3s;
        }
    }
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    height: 100px;
    width: 100px;
    outline: none;
    background-image: none;
    color: #fff;
}

.carousel-control-next-icon:after {
    content: '›';
    font-size: 55px;
    font-family: 'BentonSans-Light';
}

.carousel-control-prev-icon:after {
    content: '‹';
    font-size: 55px;
    font-family: 'BentonSans-Light';
}

.pb-50 {
    padding-bottom: 50px !important;
}

.pb-75 {
    padding-bottom: 75px !important;
}

.py-50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
}

.py-100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
}

$header-bg-top-line: #ffffff 0%, #2b353f 100%;

.gradient-top {
    position: relative;

    &::before {
        position: absolute;
        top: 0;
        display: block;
        height: 80px;
        width: 100%;
        content: ' ';
        background-image: linear-gradient(to bottom, #d9dadb 0%, #ffffff 100%);
    }
}

.content-divider {
    padding-top: 60px;
    margin-bottom: 75px;
    border-bottom: 4px solid #d9dee2;
}

.block-grey {
    background-color: $grey-100;
    border-top: 1px solid $grey-500;
    border-bottom: 1px solid $grey-500;
}

.responsive-break-md::after,
.responsive-break-lg::after {
    content: none;
}

@include media-breakpoint-up(md) {
    .responsive-break-md::after {
        content: "\A";
        white-space: pre;
    }

    .w-md-30 {
        width: 30% !important;
    }

    .w-md-40 {
        width: 40% !important;
    }

    .w-md-50 {
        width: 50% !important;
    }

    .w-md-60 {
        width: 60% !important;
    }

    .w-md-70 {
        width: 70% !important;
    }

    .w-md-75 {
        width: 75% !important;
    }

    .w-md-100 {
        width: 100% !important;
    }

    .btn-primary {
        padding-right: 4rem;
        padding-left: 4rem;
    }

    .fullwidth {
        max-width: 1400px;
        margin-left: auto;
        margin-right: auto;
    }

    .btn-primary,
    .btn-primary:visited {
        &.two-lines {
            min-height: 64px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .table {
        &.table-cygnus-dual {
            th.table-heading {
                font-size: 21px;
            }
            th.column-heading {
                font-size: 1.1rem;
            }
            tr {
                th, td {
                    font-size: 1.1rem;
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .responsive-break-lg::after {
        content: "\A";
        white-space: pre;
    }

    .pb-lg-75 {
        padding-bottom: 75px !important;
    }

    .pt-lg-75 {
        padding-top: 75px !important;
    }

    .pb-lg-100 {
        padding-bottom: 100px !important;
    }

    .pt-lg-100 {
        padding-top: 100px !important;
    }

    .py-lg-100 {
        padding-top: 100px !important;
        padding-bottom: 100px !important;
    }
}

@media #{$desktop} {}

sup.required {
  color: red;
}

label:has(+ input[data-validators~="phone"]),
label:has(+ input[data-validators~="email"]),
label:has(+ input[data-validators~="required"]),
label:has(+ textarea[data-validators~="required"]),
label:has(+ select[data-validators~="required"]) {
  &:after {
    display: inline-block;
    padding-left: .2rem;
    content: " *";
    color: red;
  }
}
