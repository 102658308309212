@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/variables';

@import '../../css/mixins';
@import '../../css/variables';

.component-maps-our-story {
    .image-holder {
        margin: 0 auto;
        img {
            max-width: 100%;
        }
    }
    .label-holder {
        margin-top: 30px;
        h4 {
            margin-bottom: 10px;
            text-transform: uppercase;
            color: $subhead;
            line-height: 1.2em;
            font-family: 'BentonSansCond-Medium';
        }
        p {
            margin-bottom: 0;
            line-height: 1.2em;
        }
    }
}

@include media-breakpoint-up(sm) {
    .component-maps-our-story {
        .label-holder {
            margin-top: 50px;
        }
    }
}

@include media-breakpoint-up(lg) {
    .component-maps-our-story {
        position: relative;
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
        .image-holder {
            width: 778px;
            height: 501px;
        }
        .label-holder {
            position: absolute;
            align-self: center;
            margin-top: 0;
            &:nth-child(2) {
                top: 340px;
                transform: translateX(-380px);
            }
            &:nth-child(3) {
                bottom: 0px;
                margin-left: -100px;
                transform: translateX(120px);
            }
            &:nth-child(4) {
                top: 100px;
                transform: translateX(460px);
            }
            &:nth-child(5) {
                top: 225px;
                margin-left: 120px;
                transform: translateX(380px);
            }
            &:nth-child(6) {
                bottom: 60px;
                margin-left: -100px;
                transform: translateX(380px);
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .component-maps-our-story {
        .label-holder {
            &:nth-child(4) {
                top: 100px;
                transform: translateX(480px);
            }
            &:nth-child(6) {
                bottom: 60px;
                transform: translateX(400px);
            }
        }
    }
}
