@import '../../css/variables';

.component-toast {
    padding: 1.25rem;
    color: #fff;

    p {
        margin: 0;
        font-family: 'BentonSansCond-Medium';
        strong {
            font-family: 'BentonSansCond-Bold';
        }
        a {
            font-family: 'BentonSans-Bold';
            color: $secondary;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .btn-close {
        position: fixed;
        right: 0;
        margin: 0 20px;
        cursor: pointer;
        font-size: 1.5rem;
        font-weight: 700;
        transition: 0.3s;

        &:hover {
            color: black;
        }
    }
}