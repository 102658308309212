@import '../../css/variables';
@import '../../css/mixins';

.component-callout {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    min-height: 450px;
    min-width: 300px;
    padding: 1.5rem 3rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .callout-body {
        text-align: left;

        h3 {
            font-family: 'BentonSansCond-Bold';
            font-size: 1.25rem;
            color: $primary;
            letter-spacing: 0.25rem;
            text-shadow: 0 0 40px #fff;
        }
        p {
            margin-bottom: 20px;
            font-size: 0.8rem;
        }
    }
    .callout-cta {
        text-align: center;
        a {
            line-height: 1.5rem;
        }
    }
    &.conversation {
        background-image: url(/images/background-callout-conversation.webp);
        background-position: center top;
        background-size: cover;
    }
    &.support {
        background-image: url(/images/background-callout-support.webp);
    }
    &.who-we-are {
        background-image: url(/images/background-callout-who-we-are.webp);
    }
    &.what-we-do {
        background-image: url(/images/background-callout-what-we-do.webp);
    }
}

@media #{$desktop} {
    .component-callout {
        min-height: 550px;
        padding: 2.5rem 5rem;

        .callout-body {
            h3 {
                font-size: 1.75rem;
            }
            p {
                font-size: 1rem;
                margin-bottom: 30px;
            }
        }
        .callout-cta {
            min-height: 62px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &.conversation {
            &.fixed {
                height: 592px;
            }
        }
    }
}
