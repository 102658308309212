@import '../css/variables';

.template-home {
    main {
        .container {
            text-align: center;
        }
        section {
            h2 {
                margin-bottom: $h2-margin;
            }
            p {
                margin-bottom: $content-margin;
            }
        }
        .container-news-events {
            min-height: 400px;
            padding-top: calc($section-padding / 1.5);
            padding-bottom: calc($section-padding / 1.5);
            background: center / contain no-repeat url('/images/home-news-events-bg-glow.webp') $emphasis;

            h2 {
                color: $secondary;
            }
        }
    }
}

@media #{$desktop} {
    .template-home {
        header {
            position: relative;
            overflow: hidden;
            height: 616px;
            background: $header-bg-bottom-line;
        }
    }
}
