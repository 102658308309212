@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/variables';

@import '../../css/mixins';
@import '../../css/variables';

.component-section-copy {
    h2 {
        color: $primary;
        text-transform: uppercase;
        font-size: 1.75rem;
        text-align: center;
        margin-bottom: 50px;
    }
    h3 {
        font-size: 2rem;
    }
    h4 {
        font-family: 'BentonSans-Medium';
        font-size: 1.75rem;
        line-height: 1.5em;
        color: $blue;
    }
    h5 {
        font-size: 1.5rem;
    }
    p {
        font-size: 1.5rem;
        text-align: center;
        // font-family: 'BentonSans-Book';
        // color: $text-color;
    }
    // ul li, ol li, a {
    //     font-size: 1.5rem;
    //     font-family: 'BentonSans-Book';
    // }
    td, td ul li {
        font-size: 1.25rem;
    }
    small {
        font-size: 1rem;
    }
    *:last-child {
        margin-bottom: 0;
    }
    &.text-left {
        p, ul li, ol li {
            text-align: left;
        }
    }
}

@include media-breakpoint-up(lg) {
    .component-section-copy {
        h2 {
            font-size: 2.25rem;
        }
    }
}
