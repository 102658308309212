@import '../../css/mixins';
@import '../../css/variables';

.component-accordion {
    &.product-details {
        .accordion-head {
            font-family: 'BentonSans-Medium';
            span {
                display: none;
            }
        }

        .accordion-body {
            p, ul {
                margin: 0;
                padding-bottom: 15px;
                font-size: 1em;
            }

            .addition-info {
                padding-top: 10px;

                ol {
                    margin: 0;
                    padding-left: 10px;
                    font-size: 10px;
                    line-height: 16px;
                    color: #848484;
                    @media all and (-ms-high-contrast:none) {
                        padding-left: 15px;
                    }

                    li {
                        margin: 0;
                    }
                }
            }
        }
    }
    &.posts-list {
        .accordion-head {
            display: none;
        }
    }
    &.ops-list {
        .accordion-head {
            display: none;
        }

        .accordion-body {
            .addition-info {
                background-position: right 25px center;

                .info {
                    .name {
                        background-image: none;
                        padding-bottom: 25px;

                        img {
                            height: 35px;
                            width: auto;
                        }
                    }
                }
            }

            .content-text {
                .text {
                    h3 {
                        color: $blue-800;
                        font-weight: 400;
                        font-family: 'BentonSans-Regular';
                        font-size: 20px;
                        margin: 0;
                    }
                }
            }
        }

        .post-item {
            .content-text {
                .btn {
                    display: none;
                }
            }
        }
    }
}

@media #{$mobile} {
    .component-accordion {
        &.product-details {
            background: $grey-100;
            border-bottom: 1px solid $grey-300;

            .accordion-head {
                display: block;
                padding: 15px;
                color: $blue-800;
                font-size: 1em;
                cursor: pointer;

                &:not(:first-child) {
                    border-top: 1px solid $grey-300;
                }

                span {
                    float: right;
                    font-size: 26px;
                    display: block;
                }

                &.active {
                    span {
                        transform: rotate(180deg);
                    }
                }
            }

            .accordion-body {
                padding: 0 15px 10px;
                display: none;
                color: $black;
            }
        }
        &.posts-list {
            background: $grey-100;
            border-bottom: 1px solid $grey-300;
            .accordion-head {
                display: block;
                padding: 15px;
                color: $blue-800;
                font-size: 1em;
                font-family: 'BentonSans-Bold';
                cursor: pointer;

                &:not(:first-child) {
                    border-top: 1px solid $grey-300;
                }

                span {
                    float: right;
                    font-size: 26px;
                }

                &.active {
                    span {
                        transform: rotate(180deg);
                    }
                }
            }

            .accordion-body {
                padding: 0 15px 10px;
                display: none;
                color: $black;

                .title {
                    display: none;
                }

                .image {
                    margin-bottom: 10px;
                }

                .description {
                    margin-bottom: 15px;
                    font-size: 1em;
                    line-height: 1.38;
                    font-family: 'BentonSans-Regular';
                }
            }

            .btn {
                display: block;
            }
        }
        &.ops-list {
            background: $grey-100;
            border-top: 1px solid $grey-300;

            .accordion-head {
                display: block;
                padding: 0 15px;
                color: $blue-800;
                font-size: 1em;
                font-family: 'BentonSans-Regular';
                cursor: pointer;
                height: 40px;
                line-height: 40px;

                &:not(:first-child) {
                    border-top: 1px solid $grey-300;
                }

                span {
                    float: right;
                    font-size: 8px;
                    margin-top: 16px;
                }

                &.active {

                    span {
                        transform: rotate(180deg);
                    }
                }
            }

            .accordion-body {
                padding: 0 15px 20px 15px;
                display: none;
                color: $black;

                .content-text {
                    font-size: 16px;
                    line-height: 1.38;
                    padding-top: 13px;
                    color: $black;

                    .btn {
                        display: block;
                    }

                    .text {
                        padding-bottom: 15px;
                        h3 {
                            display: none;
                        }
                    }
                }

                .addition-info {
                    height: 115px;
                    padding: 15px;
                    border: 1px solid $grey-300;
                    background: $white;
                    box-sizing: border-box;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: right;

                    .info {
                        width: 70%;
                        font-family: 'BentonSans-Regular';

                        .name {
                            padding-top: 25px;
                            padding-bottom: 23px;
                            background-repeat: no-repeat;
                            background-size: 85px;
                            font-size: 30px;
                            line-height: 1;
                            color: $grey-700;

                            sup {
                                font-size: 20px;
                            }
                        }

                        .btn {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@media #{$desktop} {
    .component-accordion {
        &.product-details {
            .accordion-head {
                font-size: 1.1em;
                padding-bottom: 10px;
                color: $blue-800;
            }

            .accordion-body {
                padding-bottom: 40px;
            }
        }
        &.posts-list {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            flex-direction: row;
            .post-item {
                width: 335px;
                margin-bottom: 40px;
                margin-right: 20px;
                display: flex;

                .wrapper {
                    padding: 30px 30px 35px;
                    background: $grey-100;
                    border: 1px solid $grey-300;
                    box-sizing: border-box;
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    justify-content: space-between;

                    img {
                        width: 100%;
                        margin-bottom: 20px;
                        border: 1px solid $grey-300;
                        box-sizing: border-box;
                    }

                    .title {
                        color: $black;
                        font-size: 1.625em;
                        margin-bottom: 15px;
                        font-family: 'BentonSans-Regular';
                    }

                    .description {
                        color: $black;
                        font-size: 1em;
                        font-family: 'BentonSans-Regular';
                    }

                    .btn {
                        margin-top: 30px;
                        box-sizing: border-box;
                        text-align: center;
                    }
                }
            }
        }
        &.ops-list {
            .post-item {
                width: 100%;
                padding-bottom: 50px;
                position: relative;
                font-family: 'BentonSans-Regular';
                display: flex;

                .content-text {
                    background: $grey-100;
                    border: 1px solid $grey-300;
                    min-height: 260px;
                    width: 80%;
                    font-size: 15px;
                    line-height: 1.4;
                    color: $black;
                    display: flex;
                    align-items: center;

                    .text {
                        width: 50%;
                        box-sizing: border-box;
                    }
                }

                .addition-info {
                    width: 55%;
                    height: 215px;
                    top: 22px;
                    border: 1px solid $grey-300;
                    z-index: 2;
                    background: $white;
                    position: absolute;
                    background-repeat: no-repeat;
                    background-size: contain;

                    .info {
                        width: 50%;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;

                        .name {
                            background-repeat: no-repeat;
                            background-size: 110px;
                            font-size: 40px;
                            line-height: 1;
                            text-align: center;
                            padding-top: 0;
                            padding-bottom: 15px;

                            sup {
                                font-size: 20px;
                            }
                            img {
                                width: 60%;
                                height: auto;
                            }
                        }

                        .btn {
                            height: 40px;
                            line-height: 40px;
                            font-size: 16px;
                            text-align: center;
                            width: initial;
                            padding: 0 10px;
                        }

                        &.centered {
                            width: 100%;
                        }
                    }
                }

                &.left {
                    justify-content: flex-end;

                    .content-text {
                        justify-content: flex-end;

                        .text {
                            padding: 20px 70px 20px 0;
                        }
                    }

                    .addition-info {
                        left: 0;
                        background-position: right 20px center;
                    }
                }

                &.right {
                    justify-content: flex-start;

                    .content-text {
                        justify-content: flex-start;

                        .text {
                            padding: 20px 0 20px 40px;
                        }
                    }

                    .addition-info {
                        right: 0;
                        background-position: right 20px center;

                        .info {
                            float: left;
                        }
                    }
                }
            }
        }
    }
}

@media #{$mobile-desktop} {
    .component-accordion {
        &.ops-list {
            .post-item {
                .addition-info {
                    height: 150px;

                    .info {
                        width: 65%;
                        .name {
                            font-size: 1.625em;
                        }

                        .button {
                            line-height: 2em;
                            height: 2em;
                        }
                    }
                }
                .content-text {
                    min-height: 196px;
                }
            }
        }
    }
}

@media (max-width: 434px) {
    .component-accordion {
        &.ops-list {
            .accordion-head {
                font-size: 3.5vw;
            }
            .accordion-body {
                .addition-info {
                    background-size: auto 75%;
                    background-position: right 20px center;
                    .info {
                        .name {
                            img {
                                height: 25px;
                            }
                        }
                    }
                }
            }
        }
    }
}