@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@import '../../css/variables';

.component-form-contact {
    label,
    legend {
        display: inline-block;
        font-family: 'BentonSans-Medium';
        font-size: 1.1em;

        span {
            font-family: 'BentonSans-Book';
            font-size: 0.85em;
        }
        b, strong {
            font-family: 'BentonSans-Bold';
            font-weight: normal;
        }
    }

    legend {
        padding-left: 1.875em;
        font-size: 1em;
    }

    .form-check {
        label {
            font-family: 'BentonSans-Book';
        }

        input {
            margin-top: .05rem;
        }
    }

    .form-error {
        color: $yellow-500;
        display: none;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 1rem;
        .icon {
            font-size: 22px;
            width: 22px;
            height: 22px;
            margin-right: 10px;
            flex-grow: 0;
        }
        span {
            font-family: 'BentonSans-Medium';
            flex-grow: 1;
            flex-shrink: 1;
            line-height: 1.2;
        }
    }
    fieldset {
        border: none;
        padding: 0;
        margin-bottom: 1rem;
        &.form-group {
            display: block;
        }
    }

    input[type="text"],
    input[type="number"],
    input[type="email"],
    input[type="tel"],
    select {
        font-size: 1rem;
        padding: 0.75rem;
        height: 50px;
        border-radius: 0;
        font-family: 'BentonSans-Regular';
        box-sizing: border-box;
        border-style: solid;
        border-width: 1px;
    }

    textarea {
        font-size: 1rem;
        padding: 0.75rem;
        max-height: 200px;
        min-height: 100px;
        width: 100%;
        font-family: 'BentonSans-Regular';
        border-style: solid;
        border-width: 1px;
    }

    p {
        font-family: 'BentonSans-Regular';
        line-height: 1.25em;
        a {
            font-family: 'BentonSans-Medium';
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
        }
        b, strong {
            font-family: 'BentonSans-Medium';
        }
    }

    .form-group {
        margin-bottom: 1rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        & > label {
            width: 100%;
            margin-bottom: 0.5rem;
        }
        & > input,
        & > .component-select {
            width: calc(100% - 37px);
        }
        input, textarea {
            border: 1px solid $grey-400;
        }
        & > .error-icon {
            color: $yellow-500 !important;
            display: none;
            flex-grow: 0;
            flex-shrink: 0;
            font-size: 22px;
            width: 22px;
            height: 22px;
            margin: 0 5px 0 10px;
        }
        & > .error-holder {
            font-family: 'BentonSans-Medium';
            color: $yellow-500 !important;
            display: none;
            flex-grow: 1;
            flex-shrink: 1;
            width: 100%;
        }
        &.error {
            & > label {
                color: $yellow-500 !important;
            }
            & > input,
            & > .component-select {
                border: 1px solid $yellow-500 !important;
            }
            & > .error-icon {
                display: block;
            }
            & > .error-holder {
                display: block;
            }
        }
        small {
            font-size: .8em;
        }
        &.npi-number {
            display: block;
            width: calc(100% - 37px);
            small {
                display: block;
                a {
                    color: #002f6d;
                }
            }
        }
        &.zip-code {
            input {
                max-width: 150px;
            }
        }
    }

    .form-submit {
        span {
            font-family: 'BentonSans-Regular';
            display: none;

            &.error {
                color: $yellow-500;
            }
        }

        &.disabled {
            .processing {
                display: block;
            }
        }

        &.error {
            .error {
                display: block;
            }
        }
    }
    .form-holder {
        display: none;
        padding-top: 20px;
        position: relative;
        &.visible {
            display: block;
        }
    }

    .message-success {
        display: none;
    }

    form > div:not(.form-group) {
        .form-check {
            label {
                font-family: 'BentonSans-Regular';
                line-height: 1.25em;
                font-size: 1rem;
                .font-weight-bold {
                    font-family: 'BentonSans-Medium';
                }
            }
        }
    }
    &.success {
        .message-success {
            display: block;
        }
        .container-form {
            display: none;
        }
    }
    .has-errors {
        .form-error {
            display: flex;
        }
    }
    &.form-blue {
        background: rgb(0,26,45);
        background: linear-gradient(45deg, rgba(0,26,45,1) 0%, rgba(0,56,95,1) 100%);
        padding: 50px 25px 70px 25px;
        h2, p, label, legend, a {
            color: $white;
        }
        form {
            p {
                margin-bottom: 5px;
            }
        }
        .callout-text {
            font-family: 'BentonSansCond-Bold';
            margin-bottom: 40px;
            margin-top: 56px;
            font-size: 1.75rem;
            letter-spacing: 0.2em;
            text-transform: uppercase;
        }
        .custom-checkbox,
        .custom-radio {
            input[type="checkbox"]:checked+label:after {
                color: $white;
            }
            input[type="radio"]:checked+label:after {
                background-color: $white;
            }
            label {
                &::before {
                    background-color: $primary;
                    border: 2px solid $white;
                }
            }
        }
        button.btn-primary {
            margin-top: 15px;
            &[disabled] {
                &:hover {
                    border-color: transparent;
                }
            }
            &:hover {
                border-color: $secondary;
            }
        }
    }

    &.form-ssm {
        background: none;
        padding-top: 75px !important;
        padding-bottom: 75px !important;

        .col-form {
            margin-top: 40px;
        }
    }

    &.form-puraply {
        .callout-text {
            margin-top: 0;
            margin-bottom: 20px;
            font-family: 'BentonSans-Black';
            font-size: 2.1em;
            line-height: 1em;
            letter-spacing: normal;
        }
        .custom-checkbox,
        .custom-radio {
            input[type="checkbox"]:checked+label:after {
                color: $white;
            }
            input[type="radio"]:checked+label:after {
                background-color: $white;
            }
            label {
                &::before {
                    background-color: transparent;
                    border: 2px solid $white;
                }
            }
        }
        .component-select {
            .select-variants {
                & > ul {
                    li {
                        color: $grey-1000;
                    }
                }
            }
        }
        .btn-primary {
            &:hover {
                border-color: transparent !important;
            }
        }
    }

}
.component-form-contact-ssm-email {
    z-index: 1;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    background: center / cover no-repeat url('/images/home-news-events-bg-glow.webp') $emphasis;
    h2 {
        font-family: 'BentonSansCond-Bold';
        margin-top: 0;
        margin-bottom: 0 !important;
        font-size: 1.75rem !important;
        letter-spacing: 0.2em !important;
        text-transform: uppercase;
        color: $secondary !important;
    }
}
.component-form-privacy-request {
    .form-holder {
        display: block;
    }
    .message-success {
        min-height: 50vh;
    }
    h3 {
        margin-top: 20px !important;
        margin-bottom: 15px !important;
    }
    .custom-radio {
        input[type="radio"]:checked+label:after {
            top: 7px;
            transform: none;
        }

        label {
            &::before {
                top: 5px;
                transform: none;
            }
        }
    }
    button.btn-primary {
        margin-top: 15px;
    }
}

@include media-breakpoint-up(md) {
    .component-form-contact {
        &.form-blue {
            padding: 50px 60px 70px 80px;
        }
    }
}
@include media-breakpoint-up(lg) {
    .component-form-contact {
        .container-form {
            .form-holder {
                .npi-number {
                    width: 100%;
                }
                .form-group {
                    .component-select,
                    .form-control {
                        width: 80%;
                    }
                    &.form-check {
                        width: 100%;
                    }
                    & > .error-holder {
                        width: auto;
                    }
                }
            }
        }
    }
    .component-form-privacy-request {
        .container-form {
            .form-holder {
                .form-group {
                    #privacy-request-additional-details-input {
                        width: 75%;
                    }
                }
            }
        }
    }
}
@include media-breakpoint-up(xl) {
    .component-form-contact {
        .container-form {
            flex-grow: 1;
            flex-shrink: 1;
        }
        &.form-ssm {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
        }
    }
    .component-form-contact-ssm-email {
        padding: 75px 100px !important;
        .container {
            margin: 0 auto;
        }
        .col-text {
            width: 35%;
            margin-right: 5%;
            padding-left: 5%;
        }
        .col-form {
            width: 55%;
        }
    }
}

@supports (-webkit-appearance: none) {
    .component-form-contact {
        input {
            &[type="text"],
            &[type="email"],
            &[type="number"],
            &[type="number"]::-webkit-outer-spin-button,
            &[type="number"]::-webkit-inner-spin-button {
                -webkit-appearance: none;
            }
        }
    }
}

@supports (-moz-appearance: textfield) {
    .component-form-contact {
        input[type=number] {
            -moz-appearance: textfield;
        }
    }
}
