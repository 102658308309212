@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/variables';

.component-product-showcase {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    img {
        width: 100%;
    }
    .logo-holder {
        width: 230px;
        margin-bottom: 20px;
    }
    .image-holder {
        width: 345px;
    }
}

@include media-breakpoint-up(lg) {
    .component-product-showcase {
        .logo-holder {
            width: 300px;
        }
        .image-holder {
            width: 380px;
        }
    }
}
