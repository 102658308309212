@import '../css/mixins';
@import '../css/variables';

.template-product {
    main {
        & > div {
            padding-bottom: 0 !important;
        }
    }
    .product-content-holder {
        display: flex;
    }
}

@media #{$mobile} {
    .template-product {
        .product-content-holder {
            flex-direction: column-reverse;
            justify-content: flex-start;
            align-items: center;
            & > div {
                width: 100%;
            }
        }
    }
}

@media #{$desktop} {
    .template-product {
        .product-content-holder {
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
        }
    }
}

@media #{$mobile-desktop} {
    .template-product {
        .product-content-holder {
            flex-direction: column-reverse;
            justify-content: flex-start;
            align-items: center;
        }
    }
}