@import '../../css/variables';
.custom-checkbox,
.custom-radio {
    padding-left: 0;

    input[type="checkbox"],
    input[type="radio"] {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }

    input[type="radio"]:checked+label:after {
        background-color: $subhead;
    }

    input[type="checkbox"]:checked+label:after {
        content: '\2713';
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bold;
        font-size: 0.75rem;
        color: $subhead;
        line-height: 1.25;
        text-align: center;
    }

    label {
        display: inline-block;
        position: relative;
        padding-left: 1.75em;
        font-size: 1.1rem;
        line-height: 1.75em;
        font-family: 'BentonSans-Book';
        padding-top: 5px;
        padding-bottom: 0;
        width: initial !important;

        &:before {
            position: absolute;
            top: 50%;
            left: 0;
            display: block;
            width: 18px;
            height: 18px;
            pointer-events: none;
            content: "";
            background-color: white;
            border: 2px solid $grey-1000;
            transform: translateY(-50%);
            box-sizing: border-box;
        }

        &:after {
            position: absolute;
            left: 2px;
            display: block;
            width: 14px;
            height: 14px;
            content: "";
            background-color: transparent;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

.custom-radio {
    label {
        &:before {
            border-radius: 50%;
        }

        &:after {
            border-radius: 50%;
        }
    }
}

.custom-checkbox {
    label {
        &:before {
            top: 6px;
            transform: none;
        }

        &:after {
            top: 8px;
            transform: none;
        }
    }
}