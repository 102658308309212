@import '../../css/variables';
@import '../../css/mixins';

.component-news-events-carousel {
	h2 {
		margin-bottom: $content-margin;
	}

	#carousel-news-events {
		.carousel-indicators {
			top: 0;
			bottom: auto;
			margin-left: 10%;
			margin-right: 10%;

			li {
				width: auto;
				height: 25px;
				margin-right: 15px;
				margin-left: 15px;
				text-indent: 0;
				background-color: transparent;
				font-family: 'BentonSans-Medium';
				color: $accent;
				border-bottom: 0;
				opacity: 1;

				&:hover {
					color: $accent;
					text-decoration: none;
				}

				&.active {
					color: #fdfdfe;

					span {
						display: inline-block;
						border-bottom: 1px solid $accent;
					}
				}
			}
		}

		.carousel-inner {
			.carousel-item {
				min-height: 330px;
				padding: 85px 80px 40px 60px;
				color: #fff;
				text-align: left;
			}

			h4 {
				color: $accent;
				font-size: 1.25rem;
				font-family: 'BentonSans-Medium';
			}

			.slide-content {
				a {
					text-decoration: underline;
					color: $secondary;

					&:hover {
						text-decoration: none;
					}
				}
			}

			#slide1 {
				.col-left {
					.date {
						color: $accent;
						font-size: 2.5rem;
						font-family: 'BentonSansCond-Regular';
					}

					.place {
						color: $accent;
					}
				}

				.col-right {
					margin-top: 0.375rem;
					margin-left: calc(20px + 0.5rem);
				}
			}

			#slide2,
			#slide3 {
				.col-left {
					.slide-content {
						max-width: 380px;
					}
				}
			}
		}
	}
}

.component-news-events-filters {
	.filters {
		display: flex;
		align-items: center;

		.filters-group {
			display: flex;

			label, a {
				font-family: 'BentonSans-Medium';
			}

			label {
				text-transform: uppercase;
			}

			&.switches {
				div {
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					align-items: center;

					label {
						display: block;
						margin-bottom: 0;
						padding: 5px 20px;
						border: 1px solid $blue;
						line-height: 1rem;
						cursor: pointer;
						text-transform: none;
						color: $white;
						background-color: $blue;
						transition: background-color 0.5s ease;
						transition: all 0.5s ease;

						input {
							display: none;
						}

						&.checked {
							color: $white;
							background-color: $blue;
							border: 1px solid $blue;
						}

						&:not(.checked) {
							background: $grey-200;
							color: $grey-1000;
							border: 1px solid $grey-1000;
						}

						&:hover {
							color: $secondary;
						}
					}
				}
			}

			.search-input {
				position: relative;
				height: 30px;
				width: 100%;

				input {
					height: inherit;
					width: 100%;
					border: 1px solid $grey-700;
					border-radius: 15px;
					box-sizing: border-box;
					display: block;
					padding-right: 25px;
					padding-left: 15px;
				}

				button {
					position: absolute;
					width: 22px;
					height: 22px;
					top: 4px;
					bottom: 4px;
					right: 4px;
					border: none;
					background: transparent;
					padding: 0;
					margin: 0;
					cursor: pointer;
					color: $subhead;
					font-size: 1.4em;
				}
			}
		}
	}

	.applied-variants {
		display: none;

		&>div {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			flex-wrap: wrap;
			padding: 10px 0 0;

			&>.variant-item {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				height: 30px;
				background-color: $grey-100;
				border: 1px solid $grey-400;
				border-radius: 2px;
				margin-right: 10px;
				margin-bottom: 10px;

				&>div {
					height: 100%;
					flex-grow: 1;
					flex-shrink: 1;
					color: $grey-1000;
					font-size: .75em;
					line-height: 2.5em;
					box-sizing: border-box;
					padding: 0 10px;
				}

				&>button {
					width: 30px;
					height: 100%;
					flex-grow: 0;
					flex-shrink: 0;
					background-color: transparent;
					border: none;
					color: $grey-400;
					border-left: 1px solid $grey-400;
				}
			}
		}

		&>button {
			font-family: 'BentonSans-Medium';
			color: $light-blue-700;
			font-size: .875em;
			border: none;
			background: transparent;
			padding: 0;

		}

		&.active {
			display: block;
		}
	}
}

.component-news-events-list {
	.news-events-list {

		.data-list-item {
			background: $white;
			border: 1px solid $grey-400;
			box-sizing: border-box;

			.content {
				.name {
					a {
						line-height: 1.3;
						font-family: 'BentonSans-Medium';
						font-size: 1.25em;
						color: $link;
						transition: all 0.5s ease;

						&:hover {
							text-decoration: underline;
							color: $blue;
						}
					}
				}

				.read-more {
					font-size: 16px;
					padding: 0;
					font-family: 'BentonSans-Regular';
					text-align: center;
				}

				.tags {
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					align-items: center;
					flex-wrap: wrap;

					span {
						font-family: 'BentonSans-Medium';
						font-size: .825em;
						text-align: center;
						border-width: 1px;
						border-style: solid;
						padding: 5px 12px;
						margin-right: 3px;
						line-height: 1rem;
					}
				}
			}
		}
	}

	.load-more {
		position: relative;
		text-align: center;

		&::before {
			content: '';
			height: 2px;
			width: 100%;
			background: #e9eaec;
			position: absolute;
			top: calc(50% - 1px);
			left: 0;
			right: 0;
		}

		.btn {
			position: relative;
		}

		&.hidden {
			display: none;
		}
	}
}

.component-news-events-archive {
	h2 {
		font-family: 'BentonSans-Regular';
		color: $black;
		text-align: center;
	}

	.news-events-list {
		columns: auto;

		.data-list-item {
			display: flex;
			border: none;

			&>div {
				&:first-child {
					font-family: 'BentonSans-Bold';

					.date {
						display: inline-block;
						color: $pink;
					}

					.time {
						display: inline-block;
						padding-left: 5px;
						text-transform: uppercase;
						font-family: 'BentonSans-Medium';
						font-size: 0.875em;
					}

					.location {
						margin-top: 5px;
						text-transform: uppercase;
						color: $black;
					}
				}

				&:last-child {
					.name {
						font-family: 'BentonSans-Medium';
						color: $blue;
					}

					.description {
						font-family: 'BentonSans-Regular';
					}

					.read-more {
						font-family: 'BentonSans-Bold';
						color: $pink;
					}
				}
			}
		}
	}
}

@media #{$mobile} {
	.component-news-events-carousel {
		#carousel-news-events {
			.carousel-indicators {
				position: relative;
				display: block;
			}

			.carousel-inner {
				.carousel-item {
					min-height: 430px;
					padding: 40px 80px;
				}

				#slide1 {
					.col-left {
						margin-bottom: 0.75rem;

						.date {
							font-size: 2rem;
						}
					}

					.col-right {
						margin-left: 0;
					}
				}

				#slide2,
				#slide3 {
					.col-left {
						.slide-content {
							max-width: 300px;
						}
					}
				}
			}
		}
	}

	.component-news-events-filters {
		position: relative;

		.filters-switch-bar {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			height: 40px;
			background-color: $blue;

			span {
				color: $white;
				padding: 0 10px;
				box-sizing: border-box;
				flex-grow: 0;
				flex-shrink: 0;
			}

			h4 {
				padding: 0;
				margin: 0;
				font-family: 'BentonSans-Medium';
				text-transform: uppercase;
				color: $white;
				font-size: .875em;
				line-height: 2.85em;
				text-align: left;
				flex-grow: 1;
				flex-shrink: 1;
				height: 100%;
				cursor: pointer;
			}

			button {
				width: 40px;
				height: 40px;
				border: none;
				background-color: $light-blue-800;
				color: $white;
				font-size: 1.58em;
				display: none;
				cursor: pointer;
			}

			&.opened {
				button {
					display: block;
				}
			}
		}

		.filters {
			display: block;
			height: 0;
			overflow: hidden;
			position: absolute;
			width: 100%;
			top: 40px;
			background-color: $subhead;

			.filters-group {
				box-sizing: border-box;

				&.switches {
					padding: 15px;

					&>label {
						display: none;
					}

					&>div {
						display: flex;
						flex-wrap: wrap;
						align-items: center;
						width: 100%;

						label {
							margin-right: 20px
						}

						a {
							color: $light-blue-700;
							text-decoration: none;
						}
					}
				}

				// &.selects {
				//     display: block;
				//     & > label {
				//         display: block;
				//         color: $light-grey-200;
				//         box-sizing: border-box;
				//         width: 100%;
				//         padding: 0 15px 15px;
				//     }
				//     div.select {
				//         height: 40px;
				//         box-sizing: border-box;
				//         border: none;
				//         border-top: 1px solid $light-blue-700;
				//         overflow: hidden;
				//         padding: 0;
				//         background-color: $blue-900;
				//         &:last-child {
				//             border-bottom: 1px solid $light-blue-700;
				//         }
				//         & > span {
				//             z-index: 5;
				//             background-color: $blue-900;
				//             height: 38px;
				//             padding-left: 15px;
				//             color: $white;
				//             font-family: 'BentonSans-Medium';
				//         }
				//         & > button {
				//             z-index: 5;
				//             height: 38px;
				//             background-color: $blue-900;
				//             cursor: pointer;
				//             &::after {
				//                 border-color: $white transparent transparent transparent;
				//             }
				//             &::before {
				//                 display: none;
				//                 border: none;
				//                 content: "\e905";
				//                 font-family: 'icon';
				//                 width: 100%;
				//                 height: 100%;
				//                 color: $light-blue-800;
				//             }
				//             &.opened {
				//                 &::after {
				//                     display: none;
				//                 }
				//                 &::before {
				//                     display: block;
				//                 }
				//             }
				//         }
				//         & > ul {
				//             z-index: 4;
				//             transition: none;
				//             transform: none;
				//             bottom: 0;
				//             top: auto;
				//             visibility: visible;
				//             opacity: 1;
				//             background-color: $blue-900;
				//             padding-top: 0;
				//             border: none;
				//             li {
				//                 display: flex;
				//                 flex-direction: row;
				//                 justify-content: flex-start;
				//                 align-items: center;
				//                 color: $white;
				//                 &::before {
				//                     content: "";
				//                     display: block;
				//                     border: 1px solid $light-blue-800;
				//                     width: 14px;
				//                     height: 14px;
				//                     margin-right: 10px;
				//                     font-size: 25px;
				//                     line-height: .5em;
				//                     text-align: center;
				//                     color: $light-blue-800;
				//                 }
				//                 &:hover {
				//                     background-color: $subhead;
				//                 }
				//                 &.selected {
				//                     &::before {
				//                         content: "\25A0";
				//                     }
				//                 }
				//             }
				//         }
				//     }
				// }
				.search-input {
					&>button.icon-search {
						top: 50%;
						bottom: initial;
						right: 5px;
						// color: $subhead;
						transform: translateY(-50%);
					}
				}

				&:last-child {
					padding: 30px 15px;
				}
			}
		}

		.applied-variants {
			padding: 0 10px;
		}
	}

	.component-news-events-list {
		padding: 0 0 70px;

		.news-events-list {
			padding: 15px 0;
			box-sizing: border-box;
			min-height: 300px;

			.data-list-item {
				background: $white;
				border: 1px solid $grey-400;
				box-sizing: border-box;
				margin-bottom: 10px;

				.preview {
					display: none;
				}

				.content {
					display: flex;
					flex-flow: column;
					justify-content: space-between;
					align-items: stretch;
					height: 100%;
					padding: 15px 20px;

					.name {
						flex-grow: 1;
						padding-bottom: 20px;
					}

					.footer {
						display: flex;
						align-items: baseline;
					}
						
					.read-more {
						font-size: 16px;
						padding: 0;
						font-family: 'BentonSans-Regular';
						text-align: center;
					}

					.tags {
						flex-grow: 1;
						span {
							color: $blue;
							border-color: $blue;
						}
					}
				}
			}
		}

		.load-more {
			padding: 0 15px;

			&::before {
				display: none;
			}

			.btn {
				width: 100%;
			}
		}
	}

	.component-news-events-archive {
		h2 {
			margin-bottom: 20px;
			font-size: 1.625em;
			line-height: 1.32em;
		}

		.news-events-list {
			padding: 0;
			margin-bottom: 10px;

			.data-list-item {
				margin: 0;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				align-items: flex-start;
				padding: 15px;
				border-top: 1px solid $grey-200;

				&>div {
					&:first-child {
						margin-bottom: 5px;

						.date {
							margin-bottom: 0.25em;
							font-family: 'BentonSans-Bold';
							font-size: .875em;
							line-height: 1.28em;
						}

						.location {
							font-size: .875em;
							line-height: 1.28em;
						}
					}

					&:last-child {
						flex-shrink: 1;
						flex-grow: 1;

						.description,
						.read-more {
							display: none;
						}
					}
				}
			}
		}
	}
}

@media #{$desktop} {
	.component-news-events-filters {
		.filters-switch-bar {
			display: none;
		}

		.filters {
			flex-direction: row;
			justify-content: space-between;
			border-radius: 5.7px;
			background-color: $grey-200;
			padding: 15px 25px;

			.filters-group {
				display: flex;
				justify-content: flex-start;
				align-items: baseline;

				label,
				button,
				a {
					font-family: 'BentonSans-Medium';
					font-size: .875em;
					margin-right: 10px;
				}

				&>label {
					color: $grey-1000;
					flex-grow: 0;
					flex-shrink: 0;
					margin-bottom: 0;
				}

				&>div {
					flex-grow: 1;
					flex-shrink: 1;
				}

				a {
					text-decoration: none;
					color: $blue;
					transition: all 0.3s ease;

					&:hover {
						color: $secondary;
						opacity: 0.8;
					}
				}

				// &.selects {
				//     flex-grow: 1;
				//     flex-shrink: 1;
				//     & > div {
				//         display: flex;
				//         flex-direction: row;
				//         justify-content: flex-start;
				//         div.select {
				//             width: 48%;
				//             margin-right: 2%;
				//             z-index: 3;
				//             border-color: $blue-800;
				//             background-color: $white;
				//             border-radius: 5.7px;
				//             height: 30px;
				//             ul {
				//                 border-color: $blue-800;
				//                 background-color: $white;
				//                 border-radius: 5.7px;
				//                 li.selected, li:hover {
				//                     background-color: $blue-100;
				//                 }
				//             }
				//             span {
				//                 line-height: 2.14em;
				//             }
				//             span, li {
				//                 font-family: 'BentonSans-Medium';
				//                 color: $blue-800;
				//                 font-size: .875em;
				//             }
				//             button {
				//                 margin-right: 0;
				//                 height: 100%;
				//                 &::before {
				//                     display: none;
				//                 }
				//                 &::after {
				//                     border-color: $blue-800 transparent transparent transparent;
				//                     border-width: 9px 5px 0 5px;
				//                 }
				//             }
				//         }
				//     }
				// }
				&:first-child {
					max-width: 343px;
					flex-grow: 0;
					flex-shrink: 0;
				}

				&:last-child {
					max-width: 220px;
					flex-grow: 0;
					flex-shrink: 0;

					button {
						margin-right: 0;
					}
				}
			}
		}
	}

	.component-news-events-list {
		.news-events-list {
			padding-top: 45px;
			display: flex;
			flex-flow: row wrap;
			box-sizing: border-box;

			.data-list-item {
				flex: auto;
				margin-right: 3%;
				margin-bottom: 20px;
				min-width: 100px;
				max-width: 31.333%;
				border-radius: 0.25rem;

				&:nth-child(3n) {
					margin-right: 0;
				}

				.preview {
					width: 100%;
				}

				.content {
					display: flex;
					flex-flow: column;
					justify-content: space-between;
					align-items: stretch;
					height: 100%;
					padding: 20px 25px;

					.name {
						flex-grow: 1;
						padding-bottom: 30px;
					}

					.date {
						margin-bottom: 5px;
					}

					.footer {
						display: flex;
						align-items: baseline;
					}

					.read-more {
						flex: 1;
						width: 134px;
					}

					.tags {
						flex: 2;
						span {
							color: $white;
							background: $blue;
							border-color: $blue;
							font-size: .875em;
							padding: 5px 20px;
						}
					}
				}
			}
		}

		.load-more {
			margin-top: 40px;

			.btn {
				width: 207px;
			}
		}
	}

	.component-news-events-archive {
		h2 {
			margin-bottom: 30px;
			font-size: 2em;
			line-height: 1.07em;
		}

		.news-events-list {
			columns: auto;

			.data-list-item {
				margin: 0 120px 40px;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: flex-start;
				max-width: none !important;

				&>div {
					&:first-child {
						width: 260px;
						margin-right: 60px;
						flex-grow: 0;
						flex-shrink: 0;

						.date {
							margin-bottom: 5px;
							font-family: 'BentonSans-Book';
							font-size: 1.625em;
							line-height: 1.07em;
							letter-spacing: 1.04px;
						}

						.time {
							margin-bottom: 5px;
							padding-left: 0;
							font-family: 'BentonSans-Regular';
							font-size: 1em;
							line-height: 1.07em;
							letter-spacing: 1.04px;
							text-transform: uppercase;
						}

						.location {
							margin-top: 10px;
							letter-spacing: .64px;
							line-height: 1.56em;
						}
					}

					&:last-child {
						flex-shrink: 1;
						flex-grow: 1;

						.name,
						.description {
							margin-bottom: 5px;
							line-height: 1.375em;
						}

						.read-more {
							font-size: 0.75em;
							text-transform: uppercase;
							line-height: 1.83em;
						}
					}
				}
			}
		}
	}
}

@media #{$mobile-desktop} {
	.component-news-events-filters {
		.filters {
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;

			.filters-group {
				margin-bottom: 15px;
				width: 100%;

				&:first-child {
					max-width: 100%;
				}

				// &.selects {
				//     & > div {
				//         div.select {
				//             width: 22%;
				//         }
				//     }
				// }
				&:last-child {
					margin-bottom: 0;
					max-width: 100%;
				}
			}
		}
	}

	.component-news-events-list {
		.news-events-list {
			.data-list-item {
				max-width: 48.5%;

				&:nth-child(3n) {
					margin-right: 3%;
				}

				&:nth-child(2n) {
					margin-right: 0;
				}
			}
		}
	}

	.component-news-events-archive {
		.news-events-list {
			.data-list-item {
				margin: 0 0 40px;

				&>div {
					&:first-child {
						width: 220px;
						margin-right: 20px;

						.date {
							font-size: 1.425em;
						}

						.location {
							font-size: 0.85em;
						}
					}
				}
			}
		}
	}
}