@import '../../css/variables';
@import '../../css/mixins';

@media #{$mobile} {
    .component-jumbotron {
        background-color: $header-heading-mobile;
        background-repeat: no-repeat;

        &.static-image {
            .frames {
                .frame {
                    margin-top: 233px;
                }
            }
        }

        &::before,
        video {
            display: none;
        }

        .frames {
            margin-top: 318px;

            .frame {
                position: relative;
                flex: 1 0 auto;
                display: flex;
                align-items: center;
                padding: 40px 0;

                h2 {
                    color: $secondary;
                    margin-bottom: 0;
                    text-transform: uppercase;
                    font-size: 2.125rem;
                    line-height: 3rem;
                    letter-spacing: 0.625rem;
                    font-family: 'BentonSansCond-Bold';

                    small {
                        font-size: 1rem;
                        letter-spacing: 0.5rem;
                    }
                }
            }
        }

        &.home {
            background-image: url(/images/jumbotron-home-mobile.webp);
            background-position: top center;
            background-size: 767px auto;

            .callout-header {
                height: 300px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-left: 40px;
                padding-right: 40px;
            }

            .callout {
                display: block !important;
                background-color: $header-heading-mobile;
                padding: 30px 0;

                img {
                    display: none;
                }

                h2 {
                    margin-bottom: 30px;
                    font-size: 3rem;
                    line-height: 4.375rem;
                    color: $secondary;
                    text-align: center;
                }

                p {
                    text-align: center;
                    color: $white;
                    font-size: 1.25rem;

                    .btn.btn-primary {
                        background-color: $white !important;
                        color: $black !important;
                        border: 0;
                        margin-top: 32px;
                    }
                }
            }
        }

        &.advanced-wound-care-home {
            background-image: url(/images/jumbotron-advanced-wound-care-mobile.webp);
            background-position: top -50px center;
            background-size: 767px auto;
        }

        &.surgical-sports-medicine-home {
            background-image: url(/images/jumbotron-surgical-sports-medicine.webp);
            background-position: top -50px center;
            background-size: 767px auto;
        }

        &.our-story-home {
            background-image: url(/images/jumbotron-our-story-home-mobile.webp);
            background-position: top -50px right;
            background-size: 767px auto;
        }

        &.patient-provider-support-home {
            background-image: url(/images/jumbotron-patient-provider-support-home-mobile.webp);
            background-position: top -50px center;
            background-size: 767px auto;
        }

        &.resources-home {
            background-image: url(/images/jumbotron-resources-home-mobile.webp);
            background-position: top -50px center;
            background-size: 767px auto;
        }

        &.reimbursement-support-center {
            background-image: url(/images/jumbotron-reimbursement-support-center-mobile.webp);
            background-position: top -50px center;
            background-size: 767px auto;
        }

        &.customer-care-center {
            background-image: url(/images/jumbotron-customer-care-center-mobile.webp);
            background-position: top -50px center;
            background-size: 767px auto;
        }

        &.patient-focused-programs {
            background-image: url(/images/jumbotron-patient-focused-programs-mobile.webp);
            background-position: top -50px center;
            background-size: 767px auto;
        }

        &.medical-and-technical-support {
            background-image: url(/images/jumbotron-medical-and-technical-support-mobile.webp);
            background-position: top -50px center;
            background-size: 767px auto;
        }

        &.key-resources {
            background-image: url(/images/jumbotron-key-resources-mobile.webp);
            background-position: top -50px center;
            background-size: 767px auto;
        }

        &.regulatory {
            background-image: url(/images/jumbotron-regulatory-mobile.webp);
            background-position: top -50px center;
            background-size: 767px auto;
        }

        &.reimbursement {
            background-image: url(/images/jumbotron-reimbursement-mobile.webp);
            background-position: top -50px center;
            background-size: 767px auto;
        }

        &.ssm-puraply-mz {
            background-image: url(/images/jumbotron-ssm-puraplymz-mobile.webp);
            background-position: top -60px center;
            background-size: 767px auto;
        }

        &.ssm-puraply-sx {
            background-image: url(/images/jumbotron-ssm-puraplysx-mobile.webp);
            background-position: top -60px center;
            background-size: 767px auto;
        }

        &.awc-cygnus-dual {
            background-image: url(/images/jumbotron-awc-cygnus-dual-mobile.webp);
            background-position: top -60px center;
            background-size: 767px auto;

            .frames {
                margin-top: 300px;
            }
        }

        &.awc-via-matrix,
        &.awc-cygnus-matrix {
            .frames {
                margin-top: 0;
                .frame {
                    margin-top: 150px;
                }
            }
        }
    }
}

@media #{$desktop} {
    .component-jumbotron {
        @include gradient(top, $header-bg);

        .frames {
            position: relative;
            display: flex;
            flex-flow: column;
            height: 483px;
            z-index: 0;

            .frame {
                position: relative;
                flex: 1 0 auto;
                display: flex;
                align-items: center;

                h2 {
                    color: $secondary;
                    font-family: 'BentonSansCond-Bold';
                    text-transform: uppercase;
                    letter-spacing: 2rem;
                    font-size: 6rem;
                    line-height: 0.75em;

                    small {
                        font-size: 1.75rem;
                        letter-spacing: 1.125rem;
                    }
                }
            }
        }

        video {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: 1643px;
            height: 618px;
        }

        &.home {

            .callout {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: center;
                position: absolute;
                bottom: 70px;
                width: 100%;
                height: 483px;
                opacity: 0;
                transition: opacity .7s ease-in;

                img {
                    width: 700px;
                    height: auto;
                    margin: 0 auto 32px;
                    display: block;
                }

                h2 {
                    margin-bottom: 30px;
                    font-size: 3rem;
                    line-height: 4.375rem;
                    color: $secondary;
                    text-align: center;
                }

                p {
                    width: 700px;
                    margin: 0 auto;
                    text-align: center;
                    color: $white;
                    font-size: 1.75rem;

                    .btn.btn-primary {
                        background-color: $white !important;
                        color: $black !important;
                        border: 0;
                        margin-top: 32px;
                    }
                }

                &.fade-in {
                    opacity: 1;
                }
            }
        }

        // Styles for static image background on a specific page
        &.static-image {
            &::before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 1643px;
                height: 618px;
                background-size: cover;
                background-position: top center;
                background-repeat: no-repeat;
            }

            .frames {
                height: 461px;
                .frame {
                    h2 {
                        margin-bottom: 30px;
                        font-size: 3rem;
                        line-height: 4.375rem;
                    }
                }
            }

            &.reimbursement-support-center {
                &::before {
                    background-image: url(/images/jumbotron-reimbursement-support-center-desktop.webp);
                }
            }
            &.customer-care-center {
                &::before {
                    background-image: url(/images/jumbotron-customer-care-center-desktop.webp);
                }
            }
            &.patient-focused-programs {
                &::before {
                    background-image: url(/images/jumbotron-patient-focused-programs-desktop.webp);
                }
            }
            &.medical-and-technical-support {
                &::before {
                    background-image: url(/images/jumbotron-medical-and-technical-support-desktop.webp);
                }
            }
            &.key-resources {
                &::before {
                    background-image: url(/images/jumbotron-key-resources-desktop.webp);
                }
            }
            &.regulatory {
                &::before {
                    background-image: url(/images/jumbotron-regulatory-desktop.webp);
                }
            }
            &.reimbursement {
                &::before {
                    background-image: url(/images/jumbotron-reimbursement-desktop.webp);
                }
            }
            &.awc-cygnus-dual {
                &::before {
                    background-image: url(/images/jumbotron-awc-cygnus-dual-desktop.webp);
                }
            }
            &.awc-via-matrix {
                &::before {
                    background: linear-gradient(to right, #051c2c 0%, #051c2c 50%, #006b54 99%, #006b54 100%);
                }
            }
        }
    }
}

@media screen and (min-width: 1643px) {
    .component-jumbotron {
        &.static-image {
            &::before {
                width: 100%;
            }
        }
        video {
            width: 100%;
            height: auto;
        }
    }
}
