@import '../css/variables';

.template-surgical-sports-medicine {
    .component-jumbotron {
        .frames {
            .frame {
                h2 {
                    margin-bottom: 0;
                    font-size: 2.125rem;
                    line-height: 3rem;
                    letter-spacing: 0.4em;
                    font-family: 'BentonSansCond-Bold';
                }
            }
        }
    }
    .products-heading {
        padding-top: 75px;
        padding-bottom: 35px;
        h2 {
            text-align: center;
            color: #205977;
            font-size: 2.25rem;
            font-family: 'BentonSansCond-Bold';
        }
        &:first-child {
            padding-top: 20px;
        }
    }
}

@media #{$desktop} {
    .template-surgical-sports-medicine {
        header {
            position: relative;
            overflow: hidden;
            height: 616px;
        }
        .component-jumbotron {
            .frames {
                .frame {
                    h2 {
                        margin-bottom: 30px;
                        font-size: 3rem;
                        line-height: 4.375rem;
                    }
                }
            }
        }
        .products-heading {
            padding-top: 150px;
            padding-bottom: 70px;
        }
    }
}