@import '../../css/variables';
@import '../../css/mixins';

.component-header {
    a {
        transition: color .3s;
        &:hover {
            color: $secondary !important;
        }
    }

    .main-nav {
        display: flex;
        align-items: center;

        & > button {
            display: none;
        }

        #nav {
            flex: 1;
            position: relative;

            #submenu,
            .util-nav-item,
            ul > li > ul {
                display: none;
            }
        }
    }

    // Hover states
    .main-nav, .util-nav {
        ul li ul li:hover {
            background: $primary;
            & > a {
                color: #fff !important;
            }
            .icon-arrow {
                color: $secondary;
            }
        }
    }
}

@media #{$mobile} {
    .component-header {
        width: 100%;
        position: relative;
        padding: 18px 0 15px 0;

        .util-nav {
            display: none;
        }

        .main-nav {
            flex-wrap: wrap;

            #logo {
                flex: 1;
                a {
                    display: inline-block;
                    max-width: 200px;
                }
            }

            & > button {
                flex: 0;
                display: block;
                background: transparent;
                border: none;
                flex-basis: 25px;
                color: $secondary;
                font-size: 1.4em;

                & > span:last-child {
                    display: none;
                }

                &.active {
                    & > span:first-child {
                        display: none;
                    }

                    & > span:last-child {
                        display: inline;
                    }
                }
            }

            #nav {
                flex: 2;
                flex-basis: 100%;
                height: 0;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 100%;
                top: 100%;
                left: 0;
                right: 0;
                background-color: #fff;

                &:after {
                    content: '';
                    clear: both;
                }

                a {
                    text-decoration: none;
                }

                & > ul {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    z-index: 2;
                    opacity: 1;

                    & > li {
                        background-color: #fff;

                        &.with-submenu {
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            padding-right: 1em;

                            & > span {
                                display: block;
                                color: $menu-link-color;
                                font-size: .5em;
                                @include rotate(-90deg);
                            }
                        }

                        &.util-nav-item {
                            display: block;

                            &.with-submenu {
                                display: flex;

                                & > span {
                                    color: $menu-link-color;
                                }
                            }

                            // &.extra {
                            //     & > a,
                            //     &.with-submenu > span {
                            //         color: #fff;
                            //     }
                            // }
                        }

                        & > a {
                            display: inline-block;
                            padding: 6px 10px;
                            text-decoration: none;
                            color: $menu-link-color;
                            font-weight: bolder;
                        }
                    }
                }

                & #submenu {
                    position: absolute;
                    right: -100%;
                    top: -100%;
                    background-color: $menu-bg;
                    display: block;
                    width: 100%;
                    z-index: 3;
                    opacity: 0;

                    & > button {
                        padding: 0;
                        background-color: transparent;
                        border: none;
                        width: 30%;
                        color: $menu-link-color;
                        position: relative;
                        height: 2em;

                        & > span {
                            font-size: .5em;
                            display: block;
                            @include rotate(90deg);
                            position: absolute;
                            left: 3px;
                            top: -1px;
                            bottom: -1px;
                            margin: auto;
                            height: 2em;
                            width: 2em;
                        }

                        &:hover > span {
                            color: $secondary;
                        }
                    }

                    & > div.submenu-title {
                        padding: 0 10px 6px;
                        color: $submenu-title-color;
                        font-family: 'BentonSans-Regular';
                        border-bottom: 1px solid $menu-border-color;
                    }

                    & > div.submenu-container {
                        & > ul {
                            display: block;

                            & a {
                                color: $menu-link-color;
                                padding: 6px 10px;
                                display: inline-block;
                            }

                            & > li {
                                &.with-submenu {
                                    position: relative;
                                    height: 35px;
                                    overflow: hidden;

                                    &:before {
                                        content: '';
                                        background-color: $menu-bg;
                                        height: 35px;
                                        top: 0;
                                        right: 0;
                                        left: 0;
                                        display: block;
                                        position: absolute;
                                        z-index: 1;
                                    }

                                    & ul {
                                        overflow: hidden;
                                        display: block;
                                        position: absolute;
                                        left: 0;
                                        right: 0;
                                        bottom: 0;
                                        z-index: 0;
                                        background: $menu-bg;

                                        & li {
                                            &:last-child {
                                                border-bottom: 0;
                                            }

                                            & > a {
                                                padding: 6px 20px;
                                            }
                                        }
                                    }

                                    & > a {
                                        position: relative;
                                        z-index: 2;
                                    }

                                    & > span {
                                        font-size: .5em;
                                        display: block;
                                        position: absolute;
                                        top: 13px;
                                        right: 24px;
                                        transition: transform 0.4s;
                                        @include rotate(0deg);
                                        z-index: 2;
                                    }

                                    &.active  > span {
                                        @include rotate(-180deg);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        // Hover states
        .main-nav {
            #nav #submenu ul > li:hover, #nav ul > li:hover {
                background: $primary !important;
                & > a {
                    color: #fff !important;
                }
                .icon-arrow {
                    color: $secondary !important;
                }
                &::before {
                    background: $primary !important;
                }
            }
        }
    }
}

@media #{$desktop} {

    .main-nav {
        #nav > ul > li > ul > li:hover {
            .icon-arrow::before {
                color: $secondary;
            }
        }
    }
    .component-header {
        ul > li > ul {
            background-color: #fff;
            border: solid 0.4px $menu-border-color;
            min-width: 100%;
            opacity: 0;
            transition: transform 0.5s, opacity 0.4s ease 0.1s, visibility 0.3s;
            transform: scale(.8) translate(-20%, -20%);
            display: block;
            visibility: hidden;

            & > li {
                height: 30px;
                padding: 0 15px;
                line-height: 1.875em;
                background-color: transparent;
                transition: background-color 0.3s;

                & > ul {
                    min-width: 50%;
                }

                & > a {
                    color: $menu-link-color;
                }

                &:hover {
                    & > a {
                        color: $secondary;
                    }
                }
            }
        }

        ul > li:hover > ul {
            opacity: 1;
            transform: scale(1) translate(0, 0);
            visibility: visible;
        }

        .util-nav {
            height: 67px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            position: relative;
            z-index: 1;

            a {
                font-family: 'BentonSans-Medium';
                color: #fff;
                text-decoration: none;
                font-size: 0.8em;
                text-transform: uppercase;
            }

            & > ul {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                & > li {
                    position: relative;
                    margin-left: 30px;
                    line-height: 1.75em;
                    height: 32px;
                    white-space: nowrap;

                    & > span {
                        display: none;
                    }

                    & > ul {
                        top: calc(100% + 2px);

                        li {
                            a {
                                color: $menu-link-color;
                            }
                        }
                    }

                    &:hover {
                        & > a {
                            color: #fff;
                        }
                    }

                    &.extra {
                        border: 1px solid #fff;
                        border-radius: 2px;
                        padding: 0 10px;
                        background-color: transparent;
                        transition: background-color .3s;

                        & > a {
                            color: #fff;
                            font-size: 0.8em;
                            letter-spacing: 1.1px;
                        }

                        &:hover {
                            border: 1px solid $secondary;
                            & > a {
                                color: $secondary;
                            }
                        }
                    }

                    &:not(.extra) {
                        & > a {
                            letter-spacing: .05em;
                        }
                    }
                }
            }
        }

        .main-nav {
            height: 90px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            #logo {
                margin-top: 5px;
                flex-basis: 269px;
                display: flex;
                align-items: center;
                a {
                    display: block;
                    width: 100%;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }

            #nav {
                height: 100%;

                a {
                    text-decoration: none;
                    font-size: 1em;
                    font-family: 'BentonSans-Regular';
                }

                & > ul {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    align-items: center;
                    height: 100%;

                    & > li {
                        position: relative;
                        white-space: nowrap;
                        padding: 15px;

                        & > a {
                            color: #fff;
                            font-family: 'BentonSans-Medium';
                        }

                        &:hover > a {
                            color: $secondary;
                        }

                        &.with-submenu > span {
                            display: none;
                        }
                    }
                }

                & #submenu {
                    display: none;
                }
            }
        }

        .util-nav > ul > li > a:hover {
            color: $secondary;
        }

        .main-nav > #nav > ul > li,
        .util-nav > ul > li {
            & > ul {
                position: absolute;
                left: 0;
                top: 100%;
                box-shadow: 0 0 11px 0 rgba(1, 1, 1, 0.1);
                border-top: 3px solid $secondary;
                min-width: calc(100% - 4px);
                padding: 10px 0;
                display: block;

                & > li.with-submenu {
                    position: relative;
                    padding-right: 40px;

                    & > span {
                        display: block;
                        height: 15px;
                        width: 15px;
                        font-size: 8px;
                        position: absolute;
                        right: 0;
                        margin: auto 0;
                        top: -1px;
                        bottom: -1px;
                        color: $menu-link-color;
                        @include rotate(-90deg);
                    }

                    & > ul {
                        position: absolute;
                        left: 100%;
                        top: -10px;
                        border: solid 0.4px $menu-border-color;
                        padding: 10px 1px;
                        display: block;
                        & > li {
                            white-space: nowrap;
                        }
                    }

                    &:hover > ul {
                        transform: scale(1) translate(1px, 0);
                    }
                }
            }
        }
        .main-nav #nav > ul > li.main-nav-last-item > ul {
            left: initial;
            right: 0;
        }
    }

    .section-advanced-wound-care .component-header .main-nav > #nav > ul > li > a[href='/advanced-wound-care/'],
    .section-surgical-sports-medicine .component-header .main-nav > #nav > ul > li > a[href='/surgical-sports-medicine/'],
    .section-patient-provider-support .component-header .main-nav > #nav > ul > li > a[href='/patient-provider-support/'],
    .section-resources .component-header .main-nav > #nav > ul > li > a[href='/resources/'],
    .section-our-story .component-header .util-nav > ul > li > a[href='/our-story/'],
    .section-news-events .component-header .util-nav > ul > li > a[href='/news-events/'],
    .section-contact-us .component-header .util-nav > ul > li > a[href='/contact-us/'] {
        color: $secondary;
    }
    .section-contact-us .component-header .util-nav > ul > li.extra {
        border: 1px solid $secondary;
    }
}

@media #{$mobile-desktop} {
    .component-header {
        .main-nav {
            #logo {
                flex-basis: 23.5%;
            }
            #nav {
                & > ul > li {
                    margin-left: 0.5vw;
                    padding-left: 1vw;
                    padding-right: 1vw;

                    a {
                        font-size: 1.5vw;
                    }
                }
            }

        }
    }
}