@import '../css/variables';

.template-resources {
    .component-jumbotron {
        .frames {
            .frame {
                h2 {
                    font-size: 2.125rem;
                    line-height: 3rem;
                    letter-spacing: 0.4em;
                    font-family: 'BentonSansCond-Bold';
                }
            }
        }
    }
}

@media #{$desktop} {
    .template-resources {
        header {
            position: relative;
            overflow: hidden;
            height: 616px;
        }
        .component-jumbotron {
            .frames {
                .frame {
                    h2 {
                        font-size: 3rem;
                        line-height: 4.375rem;
                    }
                }
            }
        }
    }
}