@import '../css/variables';
@import '../css/mixins';

$header-bg-top-line: #0f1f2c 0%, #2b353f 100%;

.template-subpage {
    header {
        @include gradient(left, $header-bg-top-line);
        background: gradient() #0f1f2c;
    }
    main {
        section {
            h2 {
                margin-bottom: $h2-margin;
            }
            p {
                margin-bottom: $section-p-margin;
            }
        }
        & > div:first-child {
            padding-top: 100px;
            padding-bottom: 100px;
        }
    }
    .container-header {
        @include gradient(top, $main-nav-bg-subpage);
    }
}

@media #{$mobile} {
    .template-subpage {
        main {
            & > div:first-child {
                padding-top: 80px;
                padding-bottom: 80px;
            }
        }
        .component-section-copy {
            h2 + p {
                font-size: 1.25rem;
            }
        }
    }
}

@media #{$desktop} {
    .template-subpage {
        header {
            position: relative;
            height: 157px;

            &::before {
                content: '';
                display: block;
                position: absolute;
                width: 100%;
                height: 0;
                bottom: 0;
                left: 0;
                right: 0;
                box-shadow: 0 0 100px 60px #8697a0;
            }

            &::after {
                @include gradient(left, $header-bg-top-line);
                background: gradient() #0f1f2c;
                content: '';
                display: block;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }
        .container-header {
            @include gradient(top, $main-nav-bg);
        }
    }
}


#reimbursement .jumbotron-type3 {
    background-size: cover;
    background-position: center 30%;
}
#reimbursement .wrapper a.pdf {
    &:hover {
        text-decoration: none;
    }
}
#reimbursement .wrapper .pdf {
    display: block;
    padding: .5rem 0;
    text-decoration: underline;
}
#reimbursement .wrapper .pdf.bold {
    font-family: 'BentonSans-Medium';
}
#reimbursement .wrapper > small {
    color: #007297;
    font-family: 'BentonSans-Medium';
    text-align: center;
    display: block;
}
#reimbursement .wrapper #steps {
    background-color: #e2e3e5;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    list-style-position: inside;
    padding: .75rem 0;
    font-family: 'BentonSans-Regular';
    margin: 1rem auto 2rem;
}
#reimbursement .wrapper #steps li {
    width: 30%;
    margin: 0 1%;
    text-align: center;
    color: #58585a;
    border-right: 1px solid #007199;
}
#reimbursement .wrapper #steps li:last-child {
    border: none;
}
#reimbursement .wrapper #steps li span {
    color: #007199;
}
#reimbursement .wrapper table {
    border-collapse: collapse;
    background-color: #e2e3e4;
    border-radius: 10px;
    margin: 1rem 0;
}
#reimbursement .wrapper table tbody tr td:first-child {
    width: 25%;
    position: relative;
    padding: 0 1rem;
    font-family: 'BentonSans-Medium';
}
#reimbursement .wrapper table tbody tr td:last-child {
    padding: 0 1rem 0 0;
    vertical-align: bottom;
}
#reimbursement .wrapper table tbody tr:first-child td:last-child {
    padding: 1rem 1rem 0 0;
}
#reimbursement .wrapper table tbody tr:last-child td:last-child {
    padding: 0rem 1rem 1rem 0;
}
#reimbursement .wrapper table tbody tr ul {
    background-color: #fff;
    height: 100%;
    padding: 1rem 1rem 1rem 2rem;
    margin: 0;
    list-style:disc;
    list-style-position: outside;
    position: relative;
}
#reimbursement .wrapper table tbody tr:first-child ul {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
#reimbursement .wrapper table tbody tr:last-child ul {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
#reimbursement .wrapper table tbody tr td:first-child::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 1rem;
    right: 0;
    height: 1px;
    background-color: #818285;
}
#reimbursement .wrapper table tbody tr ul:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 1rem;
    height: 1px;
    background-color: #818285;
}
#reimbursement .wrapper table tbody tr:last-child td:first-child::after,
#reimbursement .wrapper table tbody tr:last-child ul:after {
    display: none;
}
#reimbursement .wrapper > ul {
    margin: 1rem 0;

}
#reimbursement .wrapper > ul > li {
    font-family: 'BentonSans-Medium';
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    line-height: 2.5rem;
}
#reimbursement .wrapper > ul > li::before {
    content: '';
    display: block;
    background-image: url(/images/reimbursement-arrow-bullet.webp);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 25px;
    height: 25px;
    margin-right: 1rem;
}
#reimbursement .wrapper > ul ul {
    list-style-image: url(/images/reimbursement-bullet-child.webp);
    list-style-position: outside;
    margin: 0;
    padding-left: 5.5rem;
    font-family: 'BentonSans-Light';
}
#reimbursement .wrapper > ul ul li {
    padding-left: .25rem;
}
#reimbursement #for-more-info {
    border: 1px solid #1f82a3;
    box-sizing: border-box;
    padding: 2% 1%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
}
#reimbursement #for-more-info::before {
    content: '';
    display: block;
    background-image: url(/images/reimbursement-for-more-info-pointer.webp);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin-right: 1.5rem;
    width:50px;
    height: 73px;
    flex-shrink: 0;
    flex-grow: 0;

}
#reimbursement #for-more-info div {
    flex-shrink: 1;
    flex-grow: 1;
    align-self: stretch;
    color: #626366;

}
#reimbursement #for-more-info div p {
    font-family: 'BentonSans-Medium';
    margin: 0;
}
#reimbursement #for-more-info div .phone,
#reimbursement #for-more-info div .email {
    color: $blue-800;
    font-family: 'BentonSans-Bold';
}
@media (max-width: 767px) {
    #reimbursement .jumbotron-type3 {
        background-size: 250%;
        background-position: bottom -30vw center;
    }
    #reimbursement h2 {
        margin-bottom: .5rem;
    }
    #reimbursement h3 {
        margin-bottom: .15rem;
    }
    #reimbursement .wrapper #steps {
        max-width: 100%;
    }
    #reimbursement .wrapper > ul > li {
        line-height: 2rem;
    }
    #reimbursement .wrapper > ul > li::before {
        width: 21px;
        height: 21px;
        margin-right: .75rem;
    }
    #reimbursement .wrapper > ul ul {
        list-style-image: url(/images/reimbursement-bullet-child-mobile.webp);
        padding-left: 4.5rem;
    }
    #reimbursement #for-more-info {
        padding: .75rem 1rem;
    }
}
@media (min-width: 768px) {
    #reimbursement .wrapper #steps {
        max-width: 75%;
    }
    #reimbursement #for-more-info {
        padding: 1rem 1.5rem;
    }
}
@media (min-width: 768px) and (max-width: 1200px) {
    #reimbursement .wrapper #steps {
        max-width: 100%;
    }
}
