$references-color: #434551;
$references-size: 0.75rem;
$references-regular: 'BentonSans-Book';
$references-bold: 'BentonSans-Bold';
.component-references {
    font-size: $references-size;
    line-height: 1rem;
    color: #434551;
    .references {
        h3 {
            margin-bottom: 0.25rem;
            font-family: $references-bold;
            font-size: $references-size;
            color: $references-color;
            display: inline;
            margin-right: 0.25rem;
        }
        ol {
            padding: 0;
            margin: 0;
            counter-reset: item;
            line-height: 0;
            display: inline;
            li {
                display: inline;
                list-style-type: none;
                counter-increment: item;
                font-family: $references-regular;
                font-size: $references-size;
                line-height: 1.4em;
                white-space: nowrap;
                &:before {
                    font-family: $references-bold;
                    padding-right: 0.2em;
                    text-align: left;
                    content: counter(item) ".";
                }
                .text-break {
                    word-break: break-all !important;
                    overflow-wrap: break-word !important;
                }
                a {
                    color: inherit;
                    &:hover {
                        color: inherit;
                        text-decoration: none;
                    }
                }
                & > span {
                    margin-right: 0.25rem;
                    white-space: normal;
                }
            }
        }
    }
}