.component-gdpr {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 5px 0 15px;
    & > p {
        margin: 1rem 0;
        max-width: calc(100% - 3rem);
    }
    & > button {
        margin-top: .5rem;
        background-color: transparent;
        font-size: 1.5rem;
        font-weight: 700;
        cursor: pointer;
        border: 0;
    }
}