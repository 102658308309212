/*
    Media query variables
 ---------------------------------------------------------------------------*/
$desktop: '(min-width: 768px)';
$mobile: '(max-width: 767px)';
$mobile-desktop: '(min-width: 768px) and (max-width: 1200px)';
$tablet: '(max-width: 1079px)';

/*
    Colors variables
  ---------------------------------------------------------------------------*/
$primary: #12253c;
$secondary: #edb92b;
$emphasis: #000f21;
$accent: #95beca;
$pink: #d72497;
$blue: #205977;
$dark-blue: #002C57;
$red: #a81b8d;
$orange: #ff9015;
$grey: #63666b;
$green: #5caf57;
$dark-green: #2F6652;
$subhead: $blue;
$link: $blue;

$cygnus-dual-green: #007A33;
$cygnus-matrix-green: #5D7975;
$via-matrix: #005F66;

$text-color: #434551;
$btn-border: #717c8a;

$section-padding: 75px;
$h1-margin: 50px;
$h2-margin: 50px;
$content-margin: 50px;
$section-p-margin: 30px;

$header-bg-top-line: #43464f;
$header-bg-bottom-line: #51545e;
$header-bg: $header-bg-top-line 0px,
$header-bg-top-line 67px,
$header-bg-bottom-line 67px,
$header-bg-bottom-line 100%;
$header-heading-mobile: #7e818d;

$main-nav-bg-color: rgba(83, 88, 95, 0.8);
$main-nav-bg: rgba(0, 0, 0, 0) 0px,
rgba(0, 0, 0, 0) 67px,
$main-nav-bg-color 67px,
$main-nav-bg-color 157px;
$main-nav-bg-mobile: rgba(18, 37, 60, 0.6);
$main-nav-bg-subpage: $main-nav-bg-color 0%,
$main-nav-bg-color 100%;

$menu-bg: #f9f9f9;
$menu-link-color: #969696;
$menu-border-color: #c8c8c8;
$submenu-title-color: #64666b;

$footer-bg-deep-blue: #041c2c;
$footer-bg-dark-blue: #002855;

$toast-bg-color: rgba(83, 88, 95, 0.9);

$light-blue-200: #f2f5f7;
$light-blue-400: #7acae1;
$light-blue-500: #32d0f6;
$light-blue-600: #4ac6dd;
$light-blue-700: #00b4df;
$light-blue-800: #2aa1c3;
$blue-100: #9ddcef;
$blue-200: #9fc3cc;
$blue-500: #0d90b6;
$blue-800: #007fa4;
$blue-900: #006481;

$grey-100: #f9f9f9;
$grey-200: #eaeaea;
$grey-300: #e2e2e2;
$grey-400: #d1d1d1;
$grey-500: #d4d4d5;
$grey-600: #c8c8c8;
$grey-640: #64686c;
$grey-700: #b0b0b0;
$grey-800: #b5b7b4;
$grey-840: #848484;
$grey-900: #969696;
$grey-1000: #64666b;
$light-grey-100: #f1f1f1;
$light-grey-200: #fefefe;
$dark-grey-300: #333333;

$white: #ffffff;
$black: #000000;

$pink-400: #df208e;
$red-500: #a5228a;

$yellow-100: #f8ebbe;
$yellow-500: #fdba12;

// The following are SCSS variables to automate some of the values.
// But don't feel limited by them. Change/replace whatever you want.
// The color of icons, text, and the big play button border.
// Try changing to #0f0
$primary-foreground-color: #fff; // #fff default
// The default color of control backgrounds is mostly black but with a little
// bit of blue so it can still be seen on all-black video frames, which are common.
// Try changing to #900
$primary-background-color: transparent;
// #2B333F default
// Try changing to true
$center-big-play-button: true; // true default
