@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/variables';

@import '../css/mixins';
@import '../css/variables';

.template-contact-us {
    .container-contact-us-form {
        text-align: left;
        .col-12 {
            padding: 24px 15px;
            box-sizing: border-box;
            #get-in-contact-form {
                .container-form {
                    & > h2 {
                        font-family: 'BentonSans-Regular';
                        font-size: 1.625em;
                        color: #000;
                        line-height: 1.38;
                        letter-spacing: .7px;
                        text-align: left;
                        margin-top: 0;
                    }
                }
                button.btn-primary {
                    margin-top: 15px;
                }
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .template-contact-us {
        .container-contact-us-form {
            .col-12 {
                padding: 15px 5.5vw 80px;
                max-width: 1440px;
                margin: 0 auto;
                #get-in-contact-form {
                    min-height: 300px;
                    .message-success {
                        min-height: inherit;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
                    &.success {
                        .message-success {
                            display: flex;
                        }
                    }
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .template-contact-us {
        .container-contact-us-form {
            .container-form {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
                & > h2 {
                    flex: 1 1 33%;
                }
                & > form {
                    flex: 1 1 66%;
                }
            }
        }
    }
}
