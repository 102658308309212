/*
   Style mixins
 ---------------------------------------------------------------------------*/
@mixin gradient($direction, $list) {
  background: -webkit-linear-gradient($direction, $list);
  background: -moz-linear-gradient($direction, $list);
  background: -o-linear-gradient($direction, $list);
  background: linear-gradient($direction, $list);
}

/*
   Transition mixins
 ---------------------------------------------------------------------------*/
@mixin rotate($degrees) {
  -webkit-transform: rotate($degrees);
  -moz-transform: rotate($degrees);
  -ms-transform: rotate($degrees);
  -o-transform: rotate($degrees);
  transform: rotate($degrees);
}

@mixin dynamicGradient($params) {
  -webkit-animation: DynamicGradient $params;
  -moz-animation: DynamicGradient $params;
  animation: DynamicGradient $params;
}