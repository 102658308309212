@import '../../css/mixins';
@import '../../css/variables';

.navigation-products {
    li {
        font-size: 1em;
        line-height: 1.53;
        &.active {
            a {
                color: $text-color;
            }
        }
    }
    a {
        color: $blue-800;
        &:hover {
            text-decoration: none;
        }
    }
    .btn {
        display: block;
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media #{$mobile} {
    .navigation-products {
        li {
            padding: 10px 15px;
            border-top: 1px solid $grey-500;
            &:last-child {
                border-bottom: 1px solid $grey-500;
            }
        }
        .btn {
            margin: 1rem;
        }
    }
}

@media #{$desktop} {
    .navigation-products {
        width: 322px;
        margin-left: 38px;
        ul {
            margin-bottom: 25px;
            background: $white;
            border: 1px solid $grey-300;
            padding: 15px 20px;
        }
        li {
            padding: 5px 0;
            &:not(:last-child) {
                border-bottom: 1px solid $grey-500;
            }
        }
        .btn {
            width: 251px;
            margin: 0 auto;
            box-sizing: border-box;
            display: block;
        }
    }
}

@media #{$mobile-desktop} {
    .navigation-products {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        margin: 0 0 25px;
        ul {
            width: 50%;
            margin-right: 15px;
            margin-bottom: 0;
        }
        .btn {
            margin: 0 auto;
        }
    }
}