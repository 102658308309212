@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/variables';

@import '../../css/mixins';
@import '../../css/variables';

.component-leaders {
    .leader-item {
        .leader-item-card {
            .image {
                img {
                    max-width: 100%;
                    max-height: 100%;
                    width: 100%;
                    border: solid 2px $grey-300;
                    border-radius: 50%;
                }
            }
            .info {
                .name {
                    color: $blue-800;
                    font-size: 1.25em;
                    line-height: 1.23;
                }
                .personal-info {
                    color: $grey-700;
                    font-size: 1em;
                    line-height: 1.25;
                }
            }
        }

        .leader-item-info {
            p {
                margin: 0;
            }
            .triangle-wrapper {
                position: absolute;
            }
        }
        .mobile-show-details {
            display: none;
        }
    }
    .mobile-show-list {
        display: none;
    }
}

@media #{$mobile} {
    .container-leaders {
        padding: 0;
    }
    .component-leaders {
        .mobile-show-list {
            padding: 0 15px;
            font-size: 14px;
            color: $blue-800;
            text-transform: uppercase;
            font-family: 'BentonSans-Bold' !important;
            cursor: pointer;
            display: none;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            height: 40px;
            line-height: .4em;
            &::before {
                font-family: 'icon' !important;
                transform: rotate(90deg);
                font-size: .5em;
                display: block;
                margin-right: 10px;
                line-height: 1.4em;
            }
            &.active {
                display: flex;
            }
        }
        .leader-item {
            position: relative;
            width: 100%;
            .leader-item-card {
                display: flex;
                cursor: pointer;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid $grey-500;
                padding-right: 1.55em;
                box-sizing: border-box;
                .image, .mobile-show-details {
                    flex-grow: 0;
                    flex-shrink: 0;
                }
                .image {
                    width: 100px;
                    height: 100%;
                    max-width: 300px;
                    padding: 12px;
                }
                .info {
                    flex-grow: 1;
                    flex-shrink: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding: 10px 20px 10px 10px;
                    height: 100%;
                    .name {
                        font-size: 1em;
                        line-height: 1.38;
                    }
                    .personal-info {
                        line-height: 1.38;
                    }
                }
                .mobile-show-details {
                    display: block;
                    transform: rotate(-90deg);
                    &:before {
                      font-size: .5em;
                      color: $blue-800;
                    }
                }
            }
            .leader-item-info {
                display: none;
                .close-info {
                    display: none;
                }
            }
            &#mobile-card-active {
                height: auto;
                .leader-item-card {
                    width: 100%;
                    display: flex;
                    flex-direction: column-reverse;
                    cursor: default;
                    padding: 0;
                    background: $grey-100;
                    .image, .info {
                        width: 100%;
                        box-sizing: border-box;
                    }
                    .info {
                        flex-grow: 1;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        padding: 20px 15px;
                        height: auto;
                        background: $grey-100;
                        border-bottom: none;
                        .name {
                            font-size: 1.25em;
                            line-height: 1.1;
                        }
                    }
                    .image {
                        padding-bottom: 15px;
                    }
                    .mobile-show-details {
                        display: none;
                    }
                }
                .leader-item-info {
                    background: $grey-100;
                    padding: 15px 15px 10px;
                    left: 0 !important;
                    border-bottom: 1px solid $grey-400;
                    .morecontent span {
                        display: none;
                    }
                    .morelink {
                        display: flex;
                        flex-direction: row-reverse;
                        justify-content: center;
                        align-items: center;
                        margin: 15px 0;
                        font-family: 'BentonSans-Book' !important;
                        cursor: pointer;
                        color: $blue-800;
                        &::before {
                            font-family: 'icon' !important;
                            font-size: .5em;
                            margin-left: 8px;
                        }
                        &.less {
                            &::before {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
            }
        }
    }
}

@media #{$desktop} {
    .container-leaders {
        max-width: 80vw;
        margin: 0 auto;
        padding: 0 15px;
    }
    .component-leaders {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        margin: 0 -40px;
        .leader-item {
            width: 25%;
            margin-bottom: 35px;
            .leader-item-card {
                padding: 0 40px;
                .image {
                    margin-bottom: 10px;
                }
                .info {
                    padding: 0 10px;
                    text-align: center;
                    .personal-info {
                        margin-top: 5px;
                    }
                }
                &:hover {
                    cursor: pointer;
                }
            }
            .leader-item-info {
                width: 840px;
                padding: 30px 85px 30px 30px;
                margin-top: 30px;
                background: $grey-100;
                border: 1px solid $grey-300;
                box-sizing: border-box;
                position: relative;
                display: none;
                p {
                    margin: 0;
                    .moreellipses {
                        display: none;
                    }
                    .morelink {
                        display: none;
                    }
                    .morecontent {
                        span {
                            display: inline-block !important;
                        }
                    }
                }
                .close-info {
                    font-size: 1.625em;
                    color: $grey-800;
                    display: inline;
                    position: absolute;
                    right: 30px;
                    cursor: pointer;
                }
                .triangle-wrapper {
                    top: -25px;
                    position: absolute;
                    z-index: 2;
                    width: 150px;
                    height: 25px;
                    &::before, &::after {
                        content: '';
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 0 75px 25px 75px;
                        position: absolute;
                        left: 0;
                        right: 0;
                    }
                    &::before {
                        top: 0;
                        border-color: transparent transparent $grey-300 transparent;
                    }
                    &::after {
                        top: 1px;
                        border-color: transparent transparent $grey-100 transparent;
                    }
                }
            }
        }
    }
}

@media #{$mobile-desktop} {
    .component-leaders {
        .leader-item {
            width: 33.3%;
            .leader-item-card {
                .image {
                    height: auto;
                }
                .info {
                    .name {
                        font-size: 1em;
                        line-height: 1;
                    }
                    .personal-info {
                        color: $grey-700;
                        font-size: .9em;
                    }
                }
            }
            .leader-item-info {
                width: 80vw;;
            }
        }
    }
}
