@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/variables';

@import '../css/variables';

.template-our-story {
    .component-jumbotron {
        .frames {
            .frame {
                h2 {
                    margin-bottom: 0;
                    font-size: 2.125rem;
                    line-height: 3rem;
                    letter-spacing: 0.4em;
                    font-family: 'BentonSansCond-Bold';
                }
            }
        }
    }
    .component-products-list {
        .row {
            .col-12 {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .scalable-heading {
        font-size: 1.65rem;
        letter-spacing: 0.2em;
    }
}

@include media-breakpoint-up(sm) {
    .template-our-story {
        .scalable-heading {
            font-size: 1.875rem;
            letter-spacing: 0.677rem;
        }
    }
}

@media #{$desktop} {
    .template-our-story {
        header {
            position: relative;
            overflow: hidden;
            height: 616px;
        }
        .component-jumbotron {
            .frames {
                .frame {
                    h2 {
                        margin-bottom: 30;
                        font-size: 3rem;
                        line-height: 4.375rem;
                    }
                }
            }
        }
    }
}
