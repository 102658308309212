@import '../../css/mixins';
@import '../../css/variables';

.component-features {
    background: $light-blue-200;
    border-left: 50px solid #fff;

    p {
        font-size: 1.125rem !important;
        text-align: left !important;
    }

    div {
        display: inline-flex;
        flex-direction: column;
        justify-content: center;

        &.icon {
            position: relative;
            padding: 15px 50px 15px 60px;
            min-height: 90px;

            &::before {
                content: '';
                width: 80px;
                height: 80px;
                display: block;
                position: absolute;
                left: -50px;
                top: 5px;
                border: 8px solid #fff;
                border-radius: 50%;
            }

            b, sup {
                display: inline;
            }
        }

        &.temperature {
            &::before {
                background: url('/images/icon-temperature.svg');
            }
            &.green {
                &::before {
                  background: url('/images/icon-temperature-green.svg');
                }
            }
        }

        &.time {
            &::before {
                background: url('/images/icon-time.svg');
            }
            &.green {
                &::before {
                  background: url('/images/icon-time-green.svg');
                }
            }
        }

        &.ebeam {
            &::before {
                background: url('/images/icon-ebeam.svg');
            }
            &.green {
                &::before {
                  background: url('/images/icon-ebeam-green.svg');
                }
            }
        }

        &.calendar {
            &::before {
                background: url('/images/icon-calendar.svg');
            }
            &.green {
                &::before {
                  background: url('/images/icon-calendar-green.svg');
                }
            }
        }
    }
}
